import { SHOW_SUCCESS_MESSAGE, HIDE_SUCCESS_MESSAGE } from '../actions/types'

const initialState = {
	message: '',
	show: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_SUCCESS_MESSAGE:
			return { ...state, message: action.payload, show: true }
		case HIDE_SUCCESS_MESSAGE:
			return { ...state, message: '', show: false }
		default:
			return state
	}
}

export default reducer
