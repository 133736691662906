import {
	GET_ALL_IMAGES,
	GET_ALL_IMAGES_SUCCESS,
	GET_ALL_IMAGES_FAIL,
	UPLOAD_IMAGE,
	NEW_IMAGE_CHANGE_FIELD,
	SET_IMAGE
} from '../actions/types'

const initialState = {
	images: null,
	image: null,
	newImage: {
		textualValue: null,
		file: null
	}
}

const imageBank = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_IMAGES:
			return {
				...state,
				loading: true
			}
		case GET_ALL_IMAGES_SUCCESS:
			return {
				...state,
				images: action.payload.data,
				loading: false
			}
		case GET_ALL_IMAGES_FAIL:
			return {
				...state,
				loading: false
			}
		case SET_IMAGE:
			return {
				...state,
				image: action.payload
			}
		case NEW_IMAGE_CHANGE_FIELD:
			return {
				...state,
				newImage: {
					...state.newImage,
					[action.payload.name]: action.payload.value
				}
			}
		default:
			return state
	}
}

export const imageBankSelector = {
	getImages: (state = initialState) => state.images,
	getImage: (state = initialState) => state.image,
	getNewImage: (state = initialState) => state.newImage
}

export default imageBank
