import {
	GET_ALL_TAGS,
	GET_ALL_TAGS_FAIL,
	GET_ALL_TAGS_SUCCESS
} from '../actions/types'
import { sortTagCategories } from '../helpers/reducerHelper'

const initialState = {
	allTags: {},
	loading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_TAGS:
			return { ...state, loading: true }
		case GET_ALL_TAGS_FAIL:
			return { ...state, loading: false }
		case GET_ALL_TAGS_SUCCESS:
			return {
				...state,
				allTags: sortTagCategories(action.payload.data),
				loading: false
			}
		default:
			return state
	}
}

export default reducer

export const getTagsSelector = (state = initialState) => state.allTags
