/**
 * Configure redux store
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import * as Sentry from '@sentry/react'
import rootReducer, {
	createReduxHistory,
	routerMiddleware
} from '../reducers/index'
import { PERSIST_KEY } from '../constants'

// Create client alias
// Used in action creators
const client = axios.create({
	baseURL: process.env.BASE_URL,
	responseType: 'json'
})

const middlewareConfig = {
	interceptors: {
		request: [
			{
				success: function({ getState, dispatch, getSourceAction }, req) {
					return req
				},
				error: function({ getState, dispatch, getSourceAction }, error) {
					return Promise.reject(error)
					//...
				}
			}
		],
		response: [
			{
				success: function({ getState, dispatch, getSourceAction }, req) {
					return req
					//contains information about request object
					//...
				},
				error: function({ getState, dispatch, getSourceAction }, error) {
					if (error.response && error.response.status === 401) {
						// dispatch(resetReducer())
						return Promise.reject(error)
					}
					return Promise.reject(error)
					//...
				}
			}
		]
	}
}

// Config redux-persist
const persistConfig = {
	key: PERSIST_KEY,
	storage,
	blacklist: [
		'router',
		'error',
		'login',
		'register',
		'category',
		'success',
		'result',
		'appState',
		// Remember to uncomment it when in production
		'section',
		'content',
		'survey',
		'loading',
		'result',
		'resultDetail',
		'template',
		'previewSection',
		'file',
		'recipient',
		'notification'
	]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
})
// Define middleware to use
const tools = [
	applyMiddleware(
		thunk,
		routerMiddleware,
		axiosMiddleware(client, middlewareConfig)
	),
	sentryReduxEnhancer
]
if (window.__REDUX_DEVTOOLS_EXTENSION__)
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())

// Create redux store
const store = createStore(persistedReducer, compose(...tools))
let persistor = persistStore(store)

const history = createReduxHistory(store)

export { store, persistor, history }
