/**
 * Created by Hai Anh on 10/9/19
 */

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isIE } from '../../helpers/functionHelpers'

export default () => {
	const { pathname } = useLocation()
	useEffect(() => {
		if (!isIE()) window.scrollTo(0, 0)
	}, [pathname])
	return null
}
