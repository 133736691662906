import {
	CLEAR_NOTIFICATION_DRAFT,
	GET_ALL_NOTIFICATIONS,
	GET_ALL_NOTIFICATIONS_SUCCESS,
	GET_UNREAD_NOTIFICATIONS_SUCCESS,
	MARK_AS_SEEN_NOTIFICATION_SUCCESS,
	UPDATE_NOTIFICATION_DRAFT
} from '../actions/types'
import { sortByDate } from '../helpers/sortHelper'
import { markAsSeenNotification } from '../actions/notification'
import { markNotificationAsSeenTransformer } from '../helpers/reducerHelper'

const initialState = {
	notifications: [],
	unreadNotification: 0,
	draft: {
		group: null,
		title: '',
		description: '',
		sendByEmail: false
	}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				notifications: action.payload.data.sort((noti1, noti2) =>
					sortByDate(noti1, noti2, 'createdAt')
				)
			}
		case UPDATE_NOTIFICATION_DRAFT:
			return {
				...state,
				draft: { ...state.draft, [action.payload.key]: action.payload.value }
			}
		case CLEAR_NOTIFICATION_DRAFT:
			return { ...state, draft: initialState.draft }
		case MARK_AS_SEEN_NOTIFICATION_SUCCESS:
			return {
				...state,
				notifications: markNotificationAsSeenTransformer(
					[...state.notifications],
					action.payload.data
				)
			}
		case GET_UNREAD_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				unreadNotification: action.payload.data || 0
			}
		default:
			return state
	}
}

export const notificationSelector = {
	getDraftGroup: (state = initialState) => state.draft.group,
	getDraftTitle: (state = initialState) => state.draft.title,
	getDraftDescription: (state = initialState) => state.draft.description,
	getDraftSendByEmail: (state = initialState) => state.draft.sendByEmail,
	getAllNotifications: (state = initialState) => state.notifications,
	getDraftForm: (state = initialState) => state.draft,
	getUnreadNotification: (state = initialState) => state.unreadNotification
}
export default reducer
