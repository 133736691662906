import {
	GET_ANALYZABLE_TEMPLATES,
	GET_ANALYZABLE_TEMPLATES_SUCCESS,
	GET_ANALYZABLE_TEMPLATES_FAIL,
	ANALYZABLE_CHANGE_FIELD,
	REMOVE_SELECTED_SUB_ORG,
	SELECT_SUB_ORG,
	SELECT_ALL_SUB_ORGS,
	REMOVE_ALL_SELECTED_SUB_ORGS,
	GET_ORGANISATION_BY_ID_SUCCESS
} from '../actions/types'

const initialState = {
	templates: [],
	selectedTemplate: null,
	loading: false,
	selectedSubOrgs: [],
	templateDetails: []
}

const analyzableTemplates = (state = initialState, action) => {
	switch (action.type) {
		case GET_ANALYZABLE_TEMPLATES:
			return {
				...state,
				loading: true
			}
		case GET_ANALYZABLE_TEMPLATES_FAIL:
			return {
				...state,
				loading: false
			}
		case GET_ANALYZABLE_TEMPLATES_SUCCESS:
			return {
				...state,
				templateDetails: action.payload.data,
				loading: false
			}
		case GET_ORGANISATION_BY_ID_SUCCESS:
			return {
				...state,
				templates: action.payload.data.analyzableTemplates,
				loading: false
			}
		case ANALYZABLE_CHANGE_FIELD:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case REMOVE_SELECTED_SUB_ORG:
			return {
				...state,
				selectedSubOrgs: state.selectedSubOrgs.filter(
					org => org.id !== action.payload.id
				)
			}
		case SELECT_SUB_ORG:
			return {
				...state,
				selectedSubOrgs: [...state.selectedSubOrgs, action.payload.org]
			}
		case SELECT_ALL_SUB_ORGS:
			return {
				...state,
				selectedSubOrgs: action.payload.orgs
			}
		case REMOVE_ALL_SELECTED_SUB_ORGS:
			return {
				...state,
				selectedSubOrgs: []
			}
		default:
			return state
	}
}

export const analyzableTemplatesSelector = {
	getTemplates: (state = initialState) => state.templates,
	getTemplateDetails: (state = initialState) => state.templateDetails,
	getSelectedTemplate: (state = initialState) => state.selectedTemplate,
	getSelectedSubOrgs: (state = initialState) => state.selectedSubOrgs
}

export default analyzableTemplates
