import {
	CHECK_USER_PASSWORD,
	CREATE_TEMP_ORGANISATION_ADMIN,
	FORGOT_PASSWORD,
	GET_CURRENT_USER,
	LOGOUT,
	UPDATE_BEARER,
	UPDATE_OTHER_USER,
	UPDATE_PASSWORD,
	UPDATE_USER,
	VERIFY_BEARER,
	VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD,
	ACTIVATE_USER,
	DEACTIVATE_USER
} from './types'
import { apiWrapper } from '../helpers/apiHelpers'

export const logout = () => (dispatch, getState) => {
	const userId = getState().user.user.id ? getState().user.user.id : -1
	return dispatch(
		apiWrapper(LOGOUT, {
			method: 'DELETE',
			url: `bearers/${userId}/${getState().user.bearer}`
		})
	)
}

export const forgotPassword = email => (dispatch, getState) => {
	return dispatch(
		apiWrapper(FORGOT_PASSWORD, {
			method: 'GET',
			url: `/user/reset/${email}`
		})
	)
}

export const updateUser = data => (dispatch, getState) => {
	const userId = getState().user.user.id
	return dispatch(
		apiWrapper(UPDATE_USER, {
			method: 'PATCH',
			url: `/users/${userId}`,
			data
		})
	)
}

export const updateOtherUser = (userId, data) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(UPDATE_OTHER_USER, {
			method: 'PATCH',
			url: `/users/${userId}`,
			data
		})
	)
}

export const deleteOtherUser = userId => (dispatch, getState) => {
	return dispatch(
		apiWrapper(UPDATE_OTHER_USER, {
			method: 'DELETE',
			url: `/users/${userId}`
		})
	)
}

export const activateUser = data => (dispatch, getState) => {
	return dispatch(
		apiWrapper(ACTIVATE_USER, {
			method: 'POST',
			url: `/user/activate`,
			data
		})
	)
}

export const deactivateUser = data => (dispatch, getState) => {
	return dispatch(
		apiWrapper(DEACTIVATE_USER, {
			method: 'POST',
			url: `/user/deactivate`,
			data
		})
	)
}

export const updatePassword = (data, bearer, userId) => (
	dispatch,
	getState
) => {
	return dispatch({
		type: UPDATE_PASSWORD,
		payload: {
			request: {
				method: 'PATCH',
				url: `/users/${userId}`,
				data,
				headers: {
					bearer
				}
			}
		}
	})
}

export const getCurrentUser = bearer => (dispatch, getState) => {
	return dispatch({
		type: GET_CURRENT_USER,
		payload: {
			request: {
				method: 'GET',
				url: `/bearers`,
				headers: {
					bearer
				}
			}
		}
	})
}

export const verifyBearer = () => (dispatch, getState) => {
	return dispatch(
		apiWrapper(VERIFY_BEARER, {
			method: 'GET',
			url: `/bearers`
		})
	)
}

export const verifyBearerForForgettingPassword = bearer => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(
			VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD,
			{
				method: 'GET',
				url: `/bearers`,
				headers: {
					bearer
				}
			},
			false
		)
	)
}

export const checkUserPassword = password => (dispatch, getState) => {
	return dispatch(
		apiWrapper(CHECK_USER_PASSWORD, {
			method: 'POST',
			url: `/bearers`,
			data: {
				email: getState().user.user.email,
				password
			}
		})
	)
}

export const createTempOrgAdmin = orgId => (dispatch, getState) => {
	return dispatch(
		apiWrapper(CREATE_TEMP_ORGANISATION_ADMIN, {
			method: 'GET',
			url: `/organizations/${orgId}/tempUser`
		})
	)
}

export const updateBearer = bearer => ({
	type: UPDATE_BEARER,
	payload: {
		value: bearer
	}
})
