/**
 * Created by Hai Anh on 7/25/19
 */
import {
	GET_ALL_CATEGORIES,
	GET_ALL_CATEGORIES_FAIL,
	GET_ALL_CATEGORIES_SUCCESS,
	GET_OWN_CATEGORIES,
	GET_OWN_CATEGORIES_FAIL,
	GET_OWN_CATEGORIES_SUCCESS,
	SET_ACCESSIBLE_CATEGORIES,
	UPDATE_CATEGORY_ACTIVE,
	UPDATE_ORGANIZATION_OWN_CATEGORIES,
	UPDATE_ORGANIZATION_OWN_CATEGORIES_FAIL,
	UPDATE_ORGANIZATION_OWN_CATEGORIES_SUCCESS
} from '../actions/types'
import { sort } from '../helpers/functionHelpers'

const initialState = {
	mainCategories: [],
	ownCategories: [],
	parentOrganizationCategories: [],
	loading: false,
	categoryActiveIndex: -1
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_ORGANIZATION_OWN_CATEGORIES:
		case GET_OWN_CATEGORIES:
		case GET_ALL_CATEGORIES:
			return { ...state, loading: true }
		case GET_ALL_CATEGORIES_SUCCESS:
			return {
				...state,
				mainCategories: sort(action.payload.data, 'id'),
				loading: false
			}
		case UPDATE_ORGANIZATION_OWN_CATEGORIES_SUCCESS:
			return {
				...state,
				ownCategories: sort(action.payload.data, 'id')
			}
		case GET_OWN_CATEGORIES_SUCCESS: {
			const parentCategories = action.payload.data.parentCategories.map(cat => {
				cat.isParent = true
				return cat
			})
			return {
				...state,
				ownCategories: sort(action.payload.data.ownCategories, 'id'),
				parentOrganizationCategories: sort(parentCategories, 'id'),
				loading: false
			}
		}
		case UPDATE_ORGANIZATION_OWN_CATEGORIES_FAIL:
		case GET_OWN_CATEGORIES_FAIL:
		case GET_ALL_CATEGORIES_FAIL:
			return { ...state, loading: false }
		case UPDATE_CATEGORY_ACTIVE:
			return {
				...state,
				categoryActiveIndex: action.payload.categoryActiveIndex
			}
		case SET_ACCESSIBLE_CATEGORIES:
			return {
				...state,
				ownCategories: action.payload
			}
		default:
			return state
	}
}

export default reducer

export const getCategories = (state = initialState) =>
	state.mainCategories || []
export const getCategoryLoading = (state = initialState) => state.loading

export const categorySelector = {
	getCategoryActiveIndex: (state = initialState) => state.categoryActiveIndex,
	getAllCategories: (state = initialState) => state.mainCategories,
	getOwnCategories: (state = initialState) => state.ownCategories,
	getParentOrganizationCategories: (state = initialState) =>
		state.parentOrganizationCategories
}
