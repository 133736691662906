import produce from 'immer'
import {
	ADD_ALL_SECTIONS,
	ADD_QUESTION,
	ADD_QUESTION_COLUMN_DRAFT,
	ADD_QUESTION_COLUMN_DRAFT_BY_INDEX,
	ADD_QUESTION_ROW_DRAFT,
	ADD_QUESTION_ROW_DRAFT_BY_INDEX,
	ADD_SECTION,
	ADD_SECTION_BY_INDEX,
	AUTO_CREATE_CONTENT_SUCCESS,
	AUTO_SAVE_CONTENT_SUCCESS,
	COPY_QUESTION,
	COPY_SECTION,
	CREATE_CONTENT_SUCCESS,
	DELETE_QUESTION_COLUMN_DRAFT,
	DELETE_QUESTION_ROW_DRAFT,
	MOVE_COLUMN,
	MOVE_ROW,
	MOVE_SECTION,
	REMOVE_QUESTION,
	REMOVE_SECTION,
	UPDATE_CONTENT_SUCCESS,
	UPDATE_QUESTION_COLUMN_DRAFT,
	UPDATE_QUESTION_DRAFT,
	UPDATE_QUESTION_ROW_DRAFT,
	UPDATE_SECTION_DRAFT,
	UPDATE_QUESTION_IMAGE_ROW_DRAFT,
	REMOVE_ALL_SECTIONS,
	EDIT_METER_TEMPLATE_CATEGORIES_SUCCESS
} from '../actions/types'
import { moveItemInArray } from '../helpers/functionHelpers'
import {
	_contentSaveTransformer,
	_sectionTemplateSaveTransformer
} from '../helpers/dataTransformer'
import i18n from '../i18n'

const initialState = [
	{
		deletable: true,
		editable: true,
		imageQuestion: false,
		title: '',
		ordinal: 1,
		page: 1,
		template: false,
		tooltip: null,
		questions: [
			{
				editable: true,
				mandatory: true,
				text: '',
				order: 1,
				type: {
					id: 1
				},
				columns: [
					{
						numericalValue: 1,
						textualValue: '',
						ordinal: 1
					}
				],
				rows: [
					{
						numericalValue: 1,
						textualValue: i18n.t('newForm.text'),
						ordinal: 1,
						isComparable: false
					}
				]
			}
		]
	}
]

const reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case UPDATE_SECTION_DRAFT:
				// Update section part
				draft[action.payload.sectionIndex][action.payload.key] =
					action.payload.value
				return
			case UPDATE_QUESTION_DRAFT:
				// Update question draft
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				][action.payload.key] = action.payload.value
				return
			case ADD_QUESTION_ROW_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].rows.push(action.payload.row)
				return
			case ADD_QUESTION_ROW_DRAFT_BY_INDEX:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].rows.splice(action.payload.rowIndex, 0, action.payload.row)
				return
			case ADD_QUESTION_COLUMN_DRAFT_BY_INDEX:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].columns.splice(action.payload.columnIndex, 0, action.payload.column)
				return
			case ADD_QUESTION_COLUMN_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].columns.push(action.payload.column)
				return
			case UPDATE_QUESTION_ROW_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].rows[action.payload.rowIndex][action.payload.key] =
					action.payload.value
				return
			case UPDATE_QUESTION_IMAGE_ROW_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].rows[action.payload.rowIndex] = action.payload.value
				return
			case DELETE_QUESTION_ROW_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].rows.splice(action.payload.rowIndex, 1)
				return
			case DELETE_QUESTION_COLUMN_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].columns.splice(action.payload.columnIndex, 1)
				return
			case UPDATE_QUESTION_COLUMN_DRAFT:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].columns[action.payload.columnIndex][action.payload.key] =
					action.payload.value
				return
			case REMOVE_SECTION:
				draft.splice(action.payload.sectionIndex, 1)
				return
			case ADD_SECTION:
				draft.push(action.payload.section)
				return
			case ADD_SECTION_BY_INDEX:
				//Inserting section after specific index
				draft.splice(action.payload.index + 1, 0, action.payload.section)
				return
			case ADD_ALL_SECTIONS:
				return action.payload.sections
			case REMOVE_ALL_SECTIONS:
				return [...initialState]
			case COPY_SECTION:
				//Copy and add the section below
				draft.splice(
					action.payload.sectionIndex + 1,
					0,
					action.payload.copySection
				)
				return
			case ADD_QUESTION:
				draft[action.payload.sectionIndex].questions.push(
					action.payload.question
				)
				return
			case REMOVE_QUESTION:
				draft[action.payload.sectionIndex].questions.splice(
					action.payload.questionIndex,
					1
				)
				return
			case COPY_QUESTION:
				//Copy and add the question below
				draft[action.payload.sectionIndex].questions.splice(
					action.payload.questionIndex + 1,
					0,
					draft[action.payload.sectionIndex].questions[
						action.payload.questionIndex
					]
				)
				return
			case MOVE_SECTION:
				draft = moveItemInArray(
					draft,
					action.payload.oldSectionIndex,
					action.payload.newSectionIndex
				)
				return
			case MOVE_ROW:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].rows = moveItemInArray(
					draft[action.payload.sectionIndex].questions[
						action.payload.questionIndex
					].rows,
					action.payload.oldRowIndex,
					action.payload.newRowIndex
				)
				return
			case MOVE_COLUMN:
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				].columns = moveItemInArray(
					draft[action.payload.sectionIndex].questions[
						action.payload.questionIndex
					].columns,
					action.payload.oldColumnIndex,
					action.payload.newColumnIndex
				)
				return
			case AUTO_SAVE_CONTENT_SUCCESS:
				return _contentSaveTransformer([...action.payload.data.sections])
			case UPDATE_CONTENT_SUCCESS:
				return _contentSaveTransformer([...action.payload.data.sections])
			case CREATE_CONTENT_SUCCESS:
				return _contentSaveTransformer([...action.payload.data.sections])
			case AUTO_CREATE_CONTENT_SUCCESS:
				return _contentSaveTransformer([...action.payload.data.sections])
			case EDIT_METER_TEMPLATE_CATEGORIES_SUCCESS:
				return _sectionTemplateSaveTransformer(action.payload.data)
		}
	})

export default reducer

export const getDraftSections = (state = initialState) => state
export const getDraftSectionId = (state = initialState) =>
	Array.isArray(state) && state.length ? (state[0].id ? state[0].id : -1) : -1
export const getDraftSectionCategories = (state = initialState) =>
	Array.isArray(state) && state.length
		? state[0].categories
			? state[0].categories
			: []
		: []
export const getDraftSectionOrganization = (state = initialState) =>
	Array.isArray(state) && state.length
		? state[0].organization
			? state[0].organization
			: null
		: null
export const getSectionMeterTitle = (state = initialState) =>
	Array.isArray(state) && state.length
		? state[0].meterTitle
			? state[0].meterTitle
			: ''
		: ''
export const getSectionMeterDefaultLanguage = (state = initialState) =>
	Array.isArray(state) && state.length
		? state[0].defaultLanguage
			? state[0].defaultLanguage
			: ''
		: ''
