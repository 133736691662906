if (!Array.prototype.find) {
	Array.prototype.find = function(predicate) {
		if (this == null) {
			throw new TypeError('Array.prototype.find called on null or undefined')
		}
		if (typeof predicate !== 'function') {
			throw new TypeError('predicate must be a function')
		}
		var list = Object(this)
		var length = list.length >>> 0
		var thisArg = arguments[1]
		var value

		for (var i = 0; i < length; i++) {
			value = list[i]
			if (predicate.call(thisArg, value, i, list)) {
				return value
			}
		}
		return undefined
	}
}
if (!String.prototype.includes) {
	String.prototype.includes = function(search, start) {
		'use strict'

		if (search instanceof RegExp) {
			throw TypeError('first argument must not be a RegExp')
		}
		if (start === undefined) {
			start = 0
		}
		return this.indexOf(search, start) !== -1
	}
}
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
		value: function(searchElement, fromIndex) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined')
			}

			// 1. Let O be ? ToObject(this value).
			var o = Object(this)

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0

			// 3. If len is 0, return false.
			if (len === 0) {
				return false
			}

			// 4. Let n be ? ToInteger(fromIndex).
			//    (If fromIndex is undefined, this step produces the value 0.)
			var n = fromIndex | 0

			// 5. If n ≥ 0, then
			//  a. Let k be n.
			// 6. Else n < 0,
			//  a. Let k be len + n.
			//  b. If k < 0, let k be 0.
			var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

			function sameValueZero(x, y) {
				return (
					x === y ||
					(typeof x === 'number' &&
						typeof y === 'number' &&
						isNaN(x) &&
						isNaN(y))
				)
			}

			// 7. Repeat, while k < len
			while (k < len) {
				// a. Let elementK be the result of ? Get(O, ! ToString(k)).
				// b. If SameValueZero(searchElement, elementK) is true, return true.
				if (sameValueZero(o[k], searchElement)) {
					return true
				}
				// c. Increase k by 1.
				k++
			}

			// 8. Return false
			return false
		}
	})
}

if (!Array.prototype.findIndex) {
	Object.defineProperty(Array.prototype, 'findIndex', {
		value: function(predicate) {
			// 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined')
			}

			var o = Object(this)

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0

			// 3. If IsCallable(predicate) is false, throw a TypeError exception.
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function')
			}

			// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
			var thisArg = arguments[1]

			// 5. Let k be 0.
			var k = 0

			// 6. Repeat, while k < len
			while (k < len) {
				// a. Let Pk be ! ToString(k).
				// b. Let kValue be ? Get(O, Pk).
				// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
				// d. If testResult is true, return k.
				var kValue = o[k]
				if (predicate.call(thisArg, kValue, k, o)) {
					return k
				}
				// e. Increase k by 1.
				k++
			}

			// 7. Return -1.
			return -1
		}
	})
}

if (Number.parseInt === undefined) {
	Number.parseInt = window.parseInt
}

if (Number.parseFloat === undefined) {
	Number.parseFloat = parseFloat
}

/**
 * indicates if a smooth behavior should be applied
 * @method shouldBailOut
 * @param {Number|Object} firstArg
 * @returns {Boolean}
 */
function shouldBailOut(firstArg) {
	if (
		firstArg === null ||
		typeof firstArg !== 'object' ||
		firstArg.behavior === undefined ||
		firstArg.behavior === 'auto' ||
		firstArg.behavior === 'instant'
	) {
		// first argument is not an object/null
		// or behavior is auto, instant or undefined
		return true
	}

	if (typeof firstArg === 'object' && firstArg.behavior === 'smooth') {
		// first argument is an object and behavior is smooth
		return false
	}

	// throw error when behavior is not supported
	throw new TypeError(
		'behavior member of ScrollOptions ' +
			firstArg.behavior +
			' is not a valid value for enumeration ScrollBehavior.'
	)
}
var w = window
var d = document

// globals
var Element = w.HTMLElement || w.Element
var original = {
	scroll: w.scroll || w.scrollTo,
	scrollBy: w.scrollBy,
	elementScroll: Element.prototype.scroll || scrollElement,
	scrollIntoView: Element.prototype.scrollIntoView
}

function scrollElement(x, y) {
	this.scrollLeft = x
	this.scrollTop = y
}

Element.prototype.scrollTo = function() {
	// avoid action when no arguments are passed
	if (arguments[0] === undefined) {
		return
	}

	// avoid smooth behavior if not required
	if (shouldBailOut(arguments[0]) === true) {
		// if one number is passed, throw error to match Firefox implementation
		if (typeof arguments[0] === 'number' && arguments[1] === undefined) {
			throw new SyntaxError('Value could not be converted')
		}

		original.elementScroll.call(
			this,
			// use left prop, first number argument or fallback to scrollLeft
			arguments[0].left !== undefined
				? ~~arguments[0].left
				: typeof arguments[0] !== 'object'
				? ~~arguments[0]
				: this.scrollLeft,
			// use top prop, second argument or fallback to scrollTop
			arguments[0].top !== undefined
				? ~~arguments[0].top
				: arguments[1] !== undefined
				? ~~arguments[1]
				: this.scrollTop
		)

		return
	}
}

if (!String.prototype.startsWith) {
	Object.defineProperty(String.prototype, 'startsWith', {
		value: function(search, rawPos) {
			var pos = rawPos > 0 ? rawPos | 0 : 0
			return this.substring(pos, pos + search.length) === search
		}
	})
}

const reduce = Function.bind.call(Function.call, Array.prototype.reduce)
const isEnumerable = Function.bind.call(
	Function.call,
	Object.prototype.propertyIsEnumerable
)
const concat = Function.bind.call(Function.call, Array.prototype.concat)
const keys = Reflect.ownKeys
