import {
	CREATE_MAILING_LIST,
	CREATE_MAILING_LIST_FAIL,
	CREATE_MAILING_LIST_SUCCESS,
	DELETE_MAILING_LIST,
	DELETE_MAILING_LIST_FAIL,
	DELETE_MAILING_LIST_SUCCESS,
	GET_MAILING_LIST,
	GET_MAILING_LIST_BY_ID_SUCCESS,
	GET_MAILING_LIST_FAIL,
	GET_MAILING_LIST_SUCCESS,
	UPDATE_MAILING_LIST,
	UPDATE_MAILING_LIST_FAIL,
	UPDATE_MAILING_LIST_SUCCESS
} from '../actions/types'

const initialState = {
	recipients: [],
	loading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_MAILING_LIST:
			return { ...state, loading: true }
		case GET_MAILING_LIST_SUCCESS:
			return { ...state, loading: false, recipients: action.payload.data }
		case GET_MAILING_LIST_FAIL:
			return { ...state, loading: false }
		case UPDATE_MAILING_LIST:
			return { ...state, loading: true }
		case UPDATE_MAILING_LIST_SUCCESS:
			return { ...state, loading: false }
		case UPDATE_MAILING_LIST_FAIL:
			return { ...state, loading: false }
		case DELETE_MAILING_LIST:
			return { ...state, loading: true }
		case DELETE_MAILING_LIST_SUCCESS:
			return { ...state, loading: false }
		case DELETE_MAILING_LIST_FAIL:
			return { ...state, loading: false }
		case CREATE_MAILING_LIST:
			return { ...state, loading: true }
		case CREATE_MAILING_LIST_FAIL:
			return { ...state, loading: false }
		case CREATE_MAILING_LIST_SUCCESS:
			return { ...state, loading: false }
		case GET_MAILING_LIST_BY_ID_SUCCESS:
			const recipient = action.payload.data
			const clone = [...state.recipients]
			const recipientIndex = clone.findIndex(item => item.id === recipient.id)
			clone.splice(recipientIndex, 1, recipient)
			return {
				...state,
				recipients: clone
			}
		default:
			return state
	}
}

export default reducer

export const recipientSelector = (state = initialState) => state.recipients

export const recipientSelectorById = (state = initialState, id) => {
	return state.recipients.find(recipient => recipient.id === id)
}

export const recipientSelectorByArrayId = (
	state = initialState,
	mailingLists
) => {
	return mailingLists.map(id => {
		return state.recipients.find(recipient => recipient.id === id)
	})
}

export const recipientDraftSelector = (state = initialState) => {
	return state.editedRecipient
}
