import { apiWrapper } from '../helpers/apiHelpers'
import {
	CREATE_CATEGORY,
	DELETE_CATEGORY,
	GET_ALL_CATEGORIES,
	GET_OWN_CATEGORIES,
	SET_ACCESSIBLE_CATEGORIES,
	UPDATE_CATEGORY,
	UPDATE_CATEGORY_ACTIVE,
	UPDATE_ORGANIZATION_OWN_CATEGORIES
} from './types'

/**
 * Created by Hai Anh on 7/25/19
 */

export const getAllCategories = () => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_ALL_CATEGORIES, {
			method: 'GET',
			url: `/categories`
		})
	)
}

export const getOwnCategories = organizationId => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_OWN_CATEGORIES, {
			method: 'GET',
			url: `/categories/organizations/${organizationId}`
		})
	)
}

export const createCategory = category => (dispatch, getState) => {
	return dispatch(
		apiWrapper(CREATE_CATEGORY, {
			method: 'POST',
			url: `/categories`,
			data: category
		})
	)
}

export const deleteCategory = id => (dispatch, getState) => {
	return dispatch(
		apiWrapper(DELETE_CATEGORY, {
			method: 'POST',
			url: `/categories/${id}`
		})
	)
}

export const updateCategory = (category, id) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(UPDATE_CATEGORY, {
			method: 'PUT',
			url: `/categories/${id}`,
			data: category
		})
	)
}

export const updateOrganizationCategories = (id, categories) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(UPDATE_ORGANIZATION_OWN_CATEGORIES, {
			method: 'POST',
			url: `/categories/organizations/${id}`,
			data: { categories }
		})
	)
}

export const updateCategoryActive = categoryActiveIndex => ({
	type: UPDATE_CATEGORY_ACTIVE,
	payload: {
		categoryActiveIndex
	}
})

export const setAccessibleCategories = categories => ({
	type: SET_ACCESSIBLE_CATEGORIES,
	payload: categories
})
