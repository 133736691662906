import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
	.use(XHR)
	.use(initReactI18next) // bind react-i18next to the instance
	.init(
		{
			backend: {
				loadPath: '/locales/{{lng}}/translation.json'
			},
			fallbackLng: 'fi',
			// debug: true,
			lng: window.localStorage.getItem('i18nextLng')?.toLowerCase(),
			interpolation: {
				escapeValue: false // not needed for react!!
			},
			whitelist: ['en', 'fi'],
			react: {
				wait: true
			}
		},
		(err, t) => {}
	)

export default i18n
