import {
	CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS,
	LOGIN_SUCCESS,
	LOGOUT,
	LOGOUT_FAIL,
	LOGOUT_SUCCESS,
	UPDATE_BEARER,
	UPDATE_USER,
	UPDATE_USER_FAIL,
	UPDATE_USER_SUCCESS,
	VERIFY_BEARER,
	VERIFY_BEARER_FAIL,
	VERIFY_BEARER_SUCCESS,
	VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD_SUCCESS
} from '../actions/types'
import { DEFAULT_DOMAIN } from '../constants'

const initialState = {
	bearer: '',
	user: {},
	loading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGOUT:
			return { ...state, loading: true }
		case LOGIN_SUCCESS:
			return {
				...state,
				bearer: action.payload.data.bearer,
				user: action.payload.data.user
			}
		case LOGOUT_SUCCESS:
			return { ...initialState }
		case LOGOUT_FAIL:
			return { ...state, loading: false }
		case UPDATE_USER:
			return { ...state, loading: true }
		case UPDATE_USER_SUCCESS:
			return { ...state, loading: false, user: action.payload.data }
		case UPDATE_USER_FAIL:
			return { ...state, loading: false }
		case VERIFY_BEARER:
			return { ...state, loading: true }
		case VERIFY_BEARER_FAIL:
			return { ...state, loading: false }
		case VERIFY_BEARER_SUCCESS:
			return { ...state, loading: false, user: action.payload.data }
		case CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS:
			return {
				...state,
				user: action.payload.data.user,
				bearer: action.payload.data.bearer
			}
		case UPDATE_BEARER:
			return {
				...state,
				bearer: action.payload.value
			}
		case VERIFY_BEARER_WITHOUT_FORGETTING_PASSWORD_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					termsAccepted: action.payload.data.termsAccepted
				}
			}
		default:
			return state
	}
}

export default reducer

export const getUser = (state = initialState) => state.user

export const showHelpSelector = (state = initialState) => {
	return state.user ? state.user.showGuide : false
}

export const isAuthenticated = state =>
	Boolean(getUser(state)) && Boolean(getBearer(state))

export const getBearer = (state = initialState) => state.bearer

export const userSelector = {
	isOrganisationAdmin: (state = initialState) =>
		state.user != null ? state.user.isOrganizationAdmin : false,
	isSuperAdmin: (state = initialState) =>
		state.user != null ? state.user.isAdmin : false,
	getUserOrganisationName: (state = initialState) =>
		state.user != null && state.user.organization != null
			? state.user.organization.name
			: '',
	getUserOrganisationId: (state = initialState) =>
		state.user != null && state.user.organization != null
			? state.user.organization.id
			: '',
	getShowGuide: (state = initialState) =>
		state.user ? state.user.showGuide : false,
	getUserOrganisationDomain: (state = initialState) =>
		state.user != null && state.user.organization != null
			? state.user.organization.domain
			: null,
	getUserId: (state = initialState) =>
		state.user != null ? state.user.id : null,
	getTermsAccepted: (state = initialState) =>
		state.user != null ? state.user.termsAccepted : null,
	isUmbrellaAdmin: (state = initialState) =>
		state.user != null ? state.user.isUmbrellaOrganizationAdmin : false
}
