import {
	CREATE_ORGANISATION,
	CREATE_ORGANISATION_FAIL,
	CREATE_ORGANISATION_SUCCESS,
	GET_ALL_ORGANISATIONS,
	GET_ALL_ORGANISATIONS_FAIL,
	GET_ALL_ORGANISATIONS_SUCCESS,
	GET_ORGANISATION_BY_ID,
	GET_ORGANISATION_BY_ID_FAIL,
	GET_ORGANISATION_BY_ID_SUCCESS,
	REQUEST_NEW_ORGANISATION,
	REQUEST_NEW_ORGANISATION_FAIL,
	REQUEST_NEW_ORGANISATION_SUCCESS,
	UPDATE_ORGANISATION,
	UPDATE_ORGANISATION_FAIL,
	UPDATE_ORGANISATION_SUCCESS,
	ORGANISATION_CHANGE_FIELD,
	GET_ORGANIZATION_SUB_ORG_STATUS,
	GET_ORGANIZATION_SUB_ORG_STATUS_SUCCESS,
	GET_ORGANIZATION_SUB_ORG_STATUS_FAIL
} from '../actions/types'

const initialState = {
	allOrganisations: [],
	isUmbrellaOrganisation: false,
	subOrganisations: [],
	analyzableTemplates: [],
	loading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_ORGANISATION:
			return { ...state, loading: true }
		case CREATE_ORGANISATION_FAIL:
			return { ...state, loading: false }
		case CREATE_ORGANISATION_SUCCESS:
			return { ...state, loading: false }
		case UPDATE_ORGANISATION:
			return { ...state, loading: true }
		case UPDATE_ORGANISATION_FAIL:
			return { ...state, loading: false }
		case UPDATE_ORGANISATION_SUCCESS:
			return { ...state, loading: false }
		case REQUEST_NEW_ORGANISATION:
			return { ...state, loading: true }
		case REQUEST_NEW_ORGANISATION_FAIL:
			return { ...state, loading: false }
		case REQUEST_NEW_ORGANISATION_SUCCESS:
			return { ...state, loading: false }
		case GET_ALL_ORGANISATIONS:
			return { ...state, loading: true }
		case GET_ALL_ORGANISATIONS_FAIL:
			return { ...state, loading: false }
		case GET_ALL_ORGANISATIONS_SUCCESS:
			return { ...state, loading: false, allOrganisations: action.payload.data }
		case GET_ORGANISATION_BY_ID:
			return { ...state, loading: true }
		case GET_ORGANISATION_BY_ID_FAIL:
			return { ...state, loading: false }
		case GET_ORGANISATION_BY_ID_SUCCESS:
			const selectableOrganizations = action.payload.data.subOrganizations
			selectableOrganizations.push({
				businessId: action.payload.data.businessId,
				createdAt: action.payload.data.createdAt,
				defaultOpenessPolicy: action.payload.data.defaultOpenessPolicy,
				domain: action.payload.data.domain,
				file: action.payload.data.file,
				id: action.payload.data.id,
				isUmbrellaOrganization: action.payload.data.isUmbrellaOrganization,
				name: action.payload.data.name,
				updatedAt: action.payload.data.updatedAt
			})
			return {
				...state,
				loading: false,
				isUmbrellaOrganisation: action.payload.data.isUmbrellaOrganization,
				subOrganisations: selectableOrganizations,
				analyzableTemplates: action.payload.data.analyzableTemplates
			}
		case GET_ORGANIZATION_SUB_ORG_STATUS:
			return { ...state, loading: true }
		case GET_ORGANIZATION_SUB_ORG_STATUS_FAIL:
			return { ...state, loading: false }
		case GET_ORGANIZATION_SUB_ORG_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				isSubOrganization: action.payload.data
			}
		case ORGANISATION_CHANGE_FIELD:
			return {
				...state,
				[action.payload.key]: action.payload.value
			}
		default:
			return state
	}
}

export default reducer

export const allOrganisationsSelector = (state = initialState) =>
	state.allOrganisations
export const organisationByIdSelector = (state = initialState, id) => {
	return state.allOrganisations.find(org => org.id === id)
}
export const organisationSelector = {
	getUmbrella: (state = initialState) => state.isUmbrellaOrganisation,
	getSubOrganisations: (state = initialState) => state.subOrganisations,
	getAnalyzableTemplates: (state = initialState) => state.analyzableTemplates,
	getUmbrellaSub: (state = initialState) => state.isSubOrganization
}
