/**
 * Login action creator
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { CLEAR_LOGIN_FORM, LOGIN, UPDATE_LOGIN_FORM } from './types'

/**
 * Dispatched when user submits login form
 *
 * @param  {object} user: The user object
 *
 * @return {object} The created login action
 */

export const login = user => {
	return {
		type: LOGIN,
		payload: {
			request: {
				method: 'POST',
				url: '/bearers',
				data: user
			}
		}
	}
}

export const updateLoginForm = (key, value) => ({
	type: UPDATE_LOGIN_FORM,
	payload: {
		key,
		value
	}
})

export const clearLoginForm = () => ({
	type: CLEAR_LOGIN_FORM
})
