/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import i18n from './i18n'

// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'root'
// Tag key
export const TAG_KEY = 'user_tag'

//App name
export const APP_NAME = 'POKKA'

//Default domain
export const DEFAULT_DOMAIN = process.env.DEFAULT_DOMAIN

export const DEFAULT_DOMAIN_GET = process.env.DEFAULT_DOMAIN_GET

export const IMAGE_BASE_URL = process.env.IMAGE_BASE_URL

export const FORM_CREATE_START_PATH = '/form'
export const ROUTER_PATH = {
	LOGIN: '/login',
	REGISTER: '/register',
	REGISTER_SUCCESS: '/register/success',
	NEW_SURVEY: '/survey',
	FORM_CATEGORY: '/form/category',
	FORM_DASHBOARD: '/form/dashboard',
	FORM_SURVEY: '/form/create',
	FORM_PREVIEW: '/form/preview',
	TEMPLATE_REVIEW: '/form/template/preview',
	EDIT_SURVEY_INFO: '/form/info/edit',
	WELCOME: '/',
	EMAIL_PREVIEW: '/form/email/preview',
	EDIT_SURVEY_THANK: '/form/thank/edit',
	SURVEY_THANK_PREVIEW: '/form/thank/preview',
	FORM_ANSWER_PUBLIC_LINK: '/:orgName/:link',
	FORM_ANSWER_PUBLIC_LINK_COLOR: '/:orgName/:link/:color',
	FORM_ANSWER_PRIVATE_LINK: '/kysely/:surveyId/:privateLink',
	FORM_ANSWER_PRIVATE_LINK_WITH_COLOR: '/kysely/:surveyId/:privateLink/:color/',
	FORM_ANSWER_PRIVATE_LINK_WITH_ANSWER:
		'/kysely/:surveyId/:privateLink/:identifier',
	FORM_ANSWER_ERROR: '/kysely/:status',
	FORM_ANSWER: '/kysely/',
	RECIPIENT_SUMMARY: '/recipient',
	RECIPIENT_ADD: '/recipient/add',
	METER_TEMPLATES: '/meter/templates',
	RECIPIENT_LIST: '/recipient/list/:id',
	FORGOT_PASSWORD: '/password/forgot',
	RESET_PASSWORD: '/password/success/:bearer',
	RESET_PASSWORD_FAIL: '/password/:type',
	CHANGE_PASSWORD: '/password/change',
	PROFILE: '/settings/profile',
	ORGANISATION_USERS: '/settings/organisation/:organisationId/users',
	ORGANISATION_ADD: '/settings/organisation/add',
	ORGANISATION_EDIT: '/settings/organisation/edit/:organisationId',
	RESULT_SUMMARY: '/results',
	RESULT_DETAIL: '/results/:surveyId',
	SURVEY_SUMMARY: '/survey/summary',
	ABOUT: '/about',
	TERM_OF_SERVICE: '/ehdot',
	SUPER_ADMIN: '/admin',
	PRINT_SECTION_RESULT_PDF: '/print/section/result/pdf/:userId/:fileId',
	PRINT_ALL_RESULT_PDF: '/print/all/result/pdf/:userId/:fileId',
	PRINT_SURVEY_PDF: '/print/survey/pdf/:userId/:fileId',
	PRINT_SECTION_RESULT_SCREENSHOT:
		'/print/section/result/screenshot/:userId/:fileId',
	PRINT_ALL_RESULT_SCREENSHOT: '/print/all/result/screenshot/:userId/:fileId',
	PRINT_SURVEY_SCREENSHOT: '/print/survey/screenshot/:userId/:fileId',
	PRINT_SURVEY_ANSWERS: '/print/survey/answers/own/:answerId',
	CREATE_DEFAULT_TEMPLATE: '/template/create',
	EDIT_DEFAULT_TEMPLATE: '/template/edit',
	EDIT_DEFAULT_METER_TEMPLATE: '/meter/template/edit',
	SURVEY_PREVIEW_WITH_ID: '/preview/survey/:surveyId',
	DELETE_SURVEY: '/delete/survey/:surveyId',
	CREATE_NOTIFICATION: '/notification/create',
	SHOW_NOTIFICATION: '/notification/show',
	EDIT_NOTIFICATION: '/notification/edit/:notiId',
	IMAGE_BANK: '/image/bank',
	ANALYSIS: '/analysis',
	ORGANISATION_RESULT: '/results/organizations/',
	LOGOUT: '/logout'
}

export const NAVIGATION_PATH = {
	RECIPIENT_LIST: '/recipient/list',
	ORGANISATION_USERS: '/settings/organisation',
	ORGANISATION_EDIT: '/settings/organisation/edit',
	RESULT_DETAIL: '/results',
	PRINT_SECTION_RESULT_PDF: '/print/section/result/pdf',
	PRINT_ALL_RESULT_PDF: '/print/all/result/pdf',
	PRINT_SURVEY_PDF: '/print/survey/pdf',
	PRINT_SECTION_RESULT_SCREENSHOT: '/print/section/result/screenshot',
	PRINT_ALL_RESULT_SCREENSHOT: '/print/all/result/screenshot',
	PRINT_SURVEY_SCREENSHOT: '/print/survey/screenshot',
	DELETE_SURVEY: '/delete/survey',
	EDIT_NOTIFICATION: '/notification/edit'
}

export const templateQuestion = typeId => {
	return [
		{
			editable: true,
			mandatory: false,
			text: '',
			order: 1,
			type: {
				id: typeId
			},
			columns: [
				{
					numericalValue: 1,
					textualValue: '',
					ordinal: 1
				}
			],
			rows: [
				{
					numericalValue: 1,
					textualValue: i18n.t('newForm.text'),
					ordinal: 1,
					isComparable: false
				}
			]
		}
	]
}

export const checkboxTemplate = (typeId, page, ordinal) => {
	return {
		deletable: true,
		title: '',
		ordinal,
		page,
		editable: true,
		template: false,
		imageQuestion: false,
		tooltip: null,
		questions: [
			{
				editable: true,
				mandatory: false,
				text: '',
				order: 1,
				type: {
					id: typeId
				},
				columns: [
					{
						numericalValue: 1,
						textualValue: '',
						ordinal: 1
					}
				],
				rows: [
					{
						numericalValue: 1,
						textualValue: i18n.t('newForm.text'),
						ordinal: 1,
						isComparable: false
					}
				]
			}
		]
	}
}

export const questionCheckboxForMeterTemplate = () => {
	return {
		editable: true,
		mandatory: false,
		text: '',
		order: 1,
		type: {
			id: 5
		},
		columns: [
			{
				numericalValue: 1,
				textualValue: '',
				ordinal: 1
			}
		],
		rows: [
			{
				numericalValue: 1,
				textualValue: i18n.t('newForm.text'),
				ordinal: 1,
				isComparable: false
			}
		]
	}
}

export const checkboxGridTemplate = () => {
	return [
		{
			editable: true,
			mandatory: false,
			text: '',
			order: 1,
			type: {
				id: 11
			},
			columns: [
				{
					numericalValue: 1,
					textualValue: i18n.t('newForm.text'),
					ordinal: 1
				}
			],
			rows: [
				{
					numericalValue: 1,
					textualValue: i18n.t('newForm.text'),
					ordinal: 1,
					isComparable: false
				}
			]
		}
	]
}

export const radioGridTemplate = () => {
	return [
		{
			editable: true,
			mandatory: false,
			text: '',
			order: 1,
			isTargetValue: false,
			type: {
				id: 10
			},
			columns: [
				{
					numericalValue: 1,
					textualValue: i18n.t('newForm.text'),
					ordinal: 1
				}
			],
			rows: [
				{
					numericalValue: 1,
					textualValue: i18n.t('newForm.text'),
					ordinal: 1,
					isComparable: false
				}
			]
		}
	]
}

export const linearScaleQuestionTemplate = [
	{
		editable: true,
		mandatory: false,
		text: '',
		useEmoji: false,
		order: 1,
		type: {
			id: 8
		},
		columns: [
			{
				numericalValue: 1,
				textualValue: `${i18n.t('newForm.text')} 1`,
				ordinal: 1
			},
			{
				numericalValue: 2,
				textualValue: '',
				ordinal: 2
			},
			{
				numericalValue: 3,
				textualValue: '',
				ordinal: 3
			},
			{
				numericalValue: 4,
				textualValue: '',
				ordinal: 4
			},
			{
				numericalValue: 5,
				textualValue: `${i18n.t('newForm.text')} 5`,
				ordinal: 5
			}
		],
		rows: [
			{
				numericalValue: 1,
				textualValue: '',
				ordinal: 1,
				isComparable: false
			}
		]
	}
]

export const imageRadioTemplate = () => {
	return [
		{
			editable: true,
			mandatory: false,
			text: '',
			order: 1,
			isTargetValue: false,
			type: {
				id: 18
			},
			columns: [
				{
					numericalValue: 1,
					textualValue: i18n.t('newForm.text'),
					ordinal: 1
				}
			],
			rows: [
				{
					numericalValue: 1,
					textualValue: '',
					ordinal: 1,
					isComparable: false,
					textualValue: i18n.t('newForm.text'),
					file: null
				}
			]
		}
	]
}

export const LINEAR_SCALE_LEFT_SELECT = [
	{
		label: 0,
		value: 0
	},
	{
		label: 1,
		value: 1
	}
]

export const LINEAR_SCALE_RIGHT_SELECT = [
	{
		label: 1,
		value: 1
	},
	{
		label: 2,
		value: 2
	},
	{
		label: 3,
		value: 3
	},
	{
		label: 4,
		value: 4
	},
	{
		label: 5,
		value: 5
	},
	{
		label: 6,
		value: 6
	},
	{
		label: 7,
		value: 7
	},
	{
		label: 8,
		value: 8
	},
	{
		label: 9,
		value: 9
	},
	{
		label: 10,
		value: 10
	}
]

export const COLORS = {
	ORANGE: '#fc4f00',
	LIGHT_ORANGE: '#f4bf6c',
	YELLOW: '#F6C065',
	WHITE: '#ffffff',
	GREEN: '#279A7F',
	GRAY: '#9d9d9d',
	BLACK: '#000',
	DARK_BLUE: '#00325a',
	EASTERN_BLUE: '#2C9AB7'
}

export const CHART_COLORS = [
	COLORS.ORANGE,
	COLORS.DARK_BLUE,
	COLORS.YELLOW,
	COLORS.GREEN,
	COLORS.GRAY,
	COLORS.EASTERN_BLUE
]

export const QUESTION_TYPE = Object.freeze({
	RADIO: 1,
	RADIO_HORIZONTAL: 2,
	SHORT_ANSWER: 3,
	PARAGRAPH: 4,
	CHECKBOX: 5,
	CHECKBOX_HORIZONTAL: 6,
	DROP_DOWN: 7,
	LINEAR_SCALE: 8,
	LINEAR_SCALE_HORIZONTAL: 9,
	RADIO_GRID: 10,
	CHECKBOX_GRID: 11,
	DATE: 12,
	TIME: 14,
	NUMBER_INPUT: 15,
	SMILEY: 16,
	DROP_DOWN_MULTIPLE: 17,
	IMAGE_RADIO: 18,
	IMAGE_CHECKBOX: 19
})

export const CHART_TYPE = Object.freeze({
	TEXT_CHART: 'TEXT_CHART',
	ROW_DATA_CHART: 'ROW_DATA_CHART',
	COLUMN_DATA_CHART: 'COLUMN_DATA_CHART',
	GRID_CHART: 'GRID_CHART'
})

export const VIEW_MODE = Object.freeze({
	EDIT_ALL: 'EDIT_ALL',
	READ_ONLY: 'READ_ONLY',
	EDIT_PART: 'EDIT_PART'
})

export const PUBLIC_LINK_STATUS = Object.freeze({
	DEFAULT: 'DEFAULT',
	INVALID: 'INVALID',
	VALID: 'VALID'
})

export const USER_ROLES = Object.freeze({
	ORGANISATION_ADMIN: 'Organisation Admin',
	SUPER_ADMIN: 'Super Admin',
	USER: 'User',
	UMBRELLA: 'Umbrella organization'
})

export const INPUT_MAX_LENGTH = 300
export const SMALL_INPUT_MAX_LENGTH = 300
export const TEXTAREA_MAX_LENGTH = 2000

export const APP_STATE = Object.freeze({
	HIDING_HEADER: 'HIDING_HEADER',
	DEFAULT: 'DEFAULT'
})

export const RESULT_DETAIL_STATE = Object.freeze({
	DEFAULT: 'DEFAULT',
	SECTION_FILTER: 'SECTION_FILTER',
	SURVEY_FILTER: 'SURVEY_FILTER',
	SURVEY_COMPARISON: 'SURVEY_COMPARISON',
	ADVANCE_FILTER: 'ADVANCE_FILTER',
	ADVANCE_COMPARISON: 'ADVANCE_COMPARISON',
	COMBINE: 'COMBINE'
})

//Save Interval
export const AUTO_SAVE_INTERVAL = 1000 * 60 * 2
export const STOP_TYPING_LIMIT = 1000 * 10
//Birth year limit
export const LOWEST_BIRTH_YEAR = 1900

//Print status
export const PRINT_STATUS = Object.freeze({
	NO_PRINT: 'NO_PRINT',
	PRINT_ALL_RESULT: 'PRINT_ALL_RESULT',
	PRINT_SECTION_RESULT: 'PRINT_SECTION_RESULT',
	PRINT_SURVEY: 'PRINT_SURVEY'
})

//Send method
export const SEND_METHOD = Object.freeze({
	PUBLIC_LINK: 'PUBLIC_LINK',
	EMAIL: 'EMAIL'
})

//Filter limit
export const FILTER_LIMIT = 6
export const COMBINE_FILTER_LIMIT = 15
export const FILTER_GROUP_LIMIT = 4
//Filter name limit
export const FILTER_NAME_LIMIT = 49
//Section title characters limit
export const SECTION_TITLE_CHARACTERS_LIMIT = 500
//Survey's user role
export const USER_ROLE = Object.freeze({
	SURVEY_CREATOR: 'SURVEY_CREATOR',
	SURVEY_EDITOR: 'SURVEY_EDITOR',
	SURVEY_VIEWER: 'SURVEY_VIEWER',
	NO_ROLE: 'NO_ROLE'
})
//Notification groups:
export const NOTIFICATION_GROUPS = {
	ALL: 'all',
	ORGANISATION_ADMINS: 'organizationAdmin',
	SUPER_ADMINS: 'admin'
}
//Print type
export const PRINT_TYPE = {
	PDF: 'PDF',
	SCREEN_SHOT: 'SCREEN_SHOT'
}
export const SCREENSHOT_WIDTH = 1024
export const SCREENSHOT_HEIGHT = 1024
//Meter comparison show
export const METER_COMPARISON_SHOW = [27, 36]
//Elamanlaatu id
export const ELAMANLAATU_ID = 27
export const KOETTU_ID = 36
export const NPS_ID = 37
//Birthyear section
export const BIRTHYEAR_SECTION_TITLE = 'syntymävuoteni'

export const LIMIT_GET_DATA = 18

export const ANSWER_TIME_ID = -100

export const ANSWERER_NUMBER_ID = -50

// filter 3
export const SURVEY_SORT = Object.freeze({
	NEWEST: 'createdAt',
	LAST_EDIT: 'updatedAt',
	NAME: 'title'
})
// filter 2
export const SURVEY_STATUS = Object.freeze({
	ALL: 'all',
	OPENED: 'opened',
	DRAFT: 'draft',
	CLOSED: 'closed',
	ARCHIVED: 'archived'
})

export const OWN_SURVEY = Object.freeze({
	OWN: true,
	ALL: false
})

export const OWN_RESULT = Object.freeze({
	ALL: false,
	OWN: true
})

export const RESULT_STATUS = Object.freeze({
	ALL: 'all',
	CLOSED: 'closed',
	OPENED: 'opened',
	ARCHIVED: 'archived',
	DRAFT: 'draft'
})

export const SORT_DIRECTION = Object.freeze({
	ASC: 'ASC',
	DESC: 'DESC'
})

export const HAPPY_METER_FILTER = {
	title: 'Elämänlaatu',
	tooltip:
		'Elämänlaatunsa (EuroHIS-8) hyväksi tuntevien kansallisen vertailun pohjana on Kansallinen terveys-, hyvinvointi- ja palvelututkimus FinSote sekä vuosien 2016-2017 osalta Aikuisväestön terveys-, hyvinvointi- ja palvelut (ATH) -tutkimus.',
	collapsibleContent: [
		{
			title: 'Ikä',
			collapsibleContent: [
				{
					text: '20 - 64-vuotiaat',
					id: '4271_27'
				},
				{
					text: '65 vuotta täyttäneet',
					id: '4272_27'
				},
				{
					text: '75 vuotta täyttäneet',
					id: '4273_27'
				}
			]
		},
		{
			title: 'Sukupuoli',
			collapsibleContent: [
				{
					text: 'Nainen',
					id: 'women_27'
				},
				{
					text: 'Mies',
					id: 'men_27'
				},
				{
					text: 'Yhteensä',
					id: 'total_27'
				}
			]
		},
		{
			title: 'Vuosi',
			collapsibleContent: [
				{
					text: '2018',
					id: '2018_27'
				},
				{
					text: '2017',
					id: '2017_27'
				},
				{
					text: '2016',
					id: '2016_27'
				}
			]
		},
		{
			title: 'Koulutus',
			collapsibleContent: [
				{
					text: 'Matala koulutus',
					id: '4274_27'
				},
				{
					text: 'Keskitason koulutus',
					id: '4275_27'
				},
				{
					text: 'Korkea koulutus',
					id: '4276_27'
				}
			]
		}
	]
}

export const LONELY_METER_FILTER = {
	title: 'Yksinäisyys',
	tooltip:
		'Itsensä yksinäiseksi tuntevien kansallisen vertailun pohjana on Kansallinen terveys-, hyvinvointi- ja palvelututkimus FinSote sekä vuosien 2016-2017 osalta Aikuisväestön terveys-, hyvinvointi- ja palvelut (ATH) -tutkimus.',
	collapsibleContent: [
		{
			title: 'Ikä',
			collapsibleContent: [
				{
					text: '20 - 64-vuotiaat',
					id: '4285_36'
				},
				{
					text: '65 vuotta täyttäneet',
					id: '4286_36'
				},
				{
					text: '75 vuotta täyttäneet',
					id: '4287_36'
				}
			]
		},
		{
			title: 'Sukupuoli',
			collapsibleContent: [
				{
					text: 'Nainen',
					id: 'women_36'
				},
				{
					text: 'Mies',
					id: 'men_36'
				},
				{
					text: 'Yhteensä',
					id: 'total_36'
				}
			]
		},
		{
			title: 'Vuosi',
			collapsibleContent: [
				{
					text: '2018',
					id: '2018_36'
				},
				{
					text: '2017',
					id: '2017_36'
				},
				{
					text: '2016',
					id: '2016_36'
				}
			]
		},
		{
			title: 'Koulutus',
			collapsibleContent: [
				{
					text: 'Matala koulutus',
					id: '4288_36'
				},
				{
					text: 'Keskitason koulutus',
					id: '4289_36'
				},
				{
					text: 'Korkea koulutus',
					id: '4290_36'
				}
			]
		}
	]
}

export const METER_DICTIONARY = {
	women_36: {
		meterId: 36,
		value: 'female',
		category: 'gender'
	},
	men_36: {
		meterId: 36,
		value: 'male',
		category: 'gender'
	},
	total_36: {
		meterId: 36,
		value: 'total',
		category: 'gender'
	},
	'2018_36': {
		meterId: 36,
		value: 2018,
		category: 'year'
	},
	'2017_36': {
		meterId: 36,
		value: 2017,
		category: 'year'
	},
	'2016_36': {
		meterId: 36,
		value: 2016,
		category: 'year'
	},
	'4285_36': {
		meterId: 36,
		value: 4285,
		category: 'indicator'
	},
	'4286_36': {
		meterId: 36,
		value: 4286,
		category: 'indicator'
	},
	'4287_36': {
		meterId: 36,
		value: 4287,
		category: 'indicator'
	},
	'4288_36': {
		meterId: 36,
		value: 4288,
		category: 'indicator'
	},
	'4289_36': {
		meterId: 36,
		value: 4289,
		category: 'indicator'
	},
	'4290_36': {
		meterId: 36,
		value: 4290,
		category: 'indicator'
	},
	women_27: {
		meterId: 27,
		value: 'female',
		category: 'gender'
	},
	men_27: {
		meterId: 27,
		value: 'male',
		category: 'gender'
	},
	total_27: {
		meterId: 27,
		value: 'total',
		category: 'gender'
	},
	'2018_27': {
		meterId: 27,
		value: 2018,
		category: 'year'
	},
	'2017_27': {
		meterId: 27,
		value: 2017,
		category: 'year'
	},
	'2016_27': {
		meterId: 27,
		value: 2016,
		category: 'year'
	},
	'4271_27': {
		meterId: 27,
		value: 4271,
		category: 'indicator'
	},
	'4272_27': {
		meterId: 27,
		value: 4272,
		category: 'indicator'
	},
	'4273_27': {
		meterId: 27,
		value: 4273,
		category: 'indicator'
	},
	'4274_27': {
		meterId: 27,
		value: 4274,
		category: 'indicator'
	},
	'4275_27': {
		meterId: 27,
		value: 4275,
		category: 'indicator'
	},
	'4276_27': {
		meterId: 27,
		value: 4276,
		category: 'indicator'
	}
}

export const SEARCH_SURVEY_TIMEOUT = 2000

export const activeImageIndex = 0
export const hiddenImageIndex = 1

export const languageOptions = [
	{ label: 'English', value: 'EN' },
	{ label: 'Suomi', value: 'FI' }
]
export const surveyLanguageOptions = [
	{ label: 'English', value: 'EN' },
	{ label: 'Suomi', value: 'FI' },
	{ label: 'Selkokieli', value: 'FI_SELKO' },
	{ label: 'Svenska', value: 'SE' }
]

export const SURVEY_AUTO_REFRESH_TIME = 5000

// TODO: These could be defined on the env variables, so that it would be dynamic with different environments and adding new ones wouldn't need changing the code
export const SPECIAL_SECTION_TEMPLATE_IDS = [41, 42, 43, 44, 45, 46]

export const SPECIAL_VISUAL_SURVEY_ROWS = [
	// These are for staging
	'e945173d-d8d7-4ff0-861b-3dd54e1f4112',
	'439158d4-dd2c-4bb0-b85a-3c451503271a',
	'750bf42c-405a-4cab-9461-895f80cf2338',
	'af507f37-1a6c-482a-b676-b1a9eab32c0c',
	// For Production
	'159e96c9-82f1-4824-9f0e-1fc1d5ff9e91',
	'455bc93a-172e-46e1-a6bd-51964caafa01',
	'8dba2260-6be8-4fea-9633-1920484192af',
	'bc29f5af-1e92-4bcc-a9a5-10d9746156b5'
]

export const SPECIAL_SURVEY_ROWS = [
	...SPECIAL_VISUAL_SURVEY_ROWS,
	// For production
	'032b29eb-29f8-4092-baad-e448049ff940',
	'521e7034-4d3c-4931-9b69-24d13ad6a6fd',
	'46883344-0e03-4631-91a2-2c1a174f3eed',
	'd512485d-9bda-4a01-bfd7-6994f2e19140',
	'46883344-0e03-4631-91a2-2c1a174f3eed',
	'd512485d-9bda-4a01-bfd7-6994f2e19140',
	'c2dfa888-1bfc-4c29-940d-daa67d6e8a2a',
	'89f9b209-a610-4b7a-82e9-d529de50929f',
	'a206dd47-b242-4679-bda3-3525d5398169',
	'78cdaac5-880d-467e-9885-f4efd4e63f00',
	'5d02a19d-b682-4adb-bab2-4f3cb67ab394',
	'0816a74d-c603-4861-aa2d-06cabe3a1542'
]
export const SPECIAL_VISUAL_SURVEY_REVERSE_IDENTIFIER = [
	// These are for staging
	'439158d4-dd2c-4bb0-b85a-3c451503271a',
	'af507f37-1a6c-482a-b676-b1a9eab32c0c',
	// For Production
	'159e96c9-82f1-4824-9f0e-1fc1d5ff9e91',
	'455bc93a-172e-46e1-a6bd-51964caafa01'
]

export const SPECIAL_SURVEY_REVERSE_IDENTIFIER = [
	...SPECIAL_VISUAL_SURVEY_REVERSE_IDENTIFIER,
	// For production, for the answers that are negative on higher the value is
	'521e7034-4d3c-4931-9b69-24d13ad6a6fd',
	'd512485d-9bda-4a01-bfd7-6994f2e19140',
	'd512485d-9bda-4a01-bfd7-6994f2e19140',
	'89f9b209-a610-4b7a-82e9-d529de50929f',
	'78cdaac5-880d-467e-9885-f4efd4e63f00',
	'0816a74d-c603-4861-aa2d-06cabe3a1542'
]

export const PRIMARY_COLOR = '#fc4f00'

export const sampleDraft = {
	email: '',
	firstName: '',
	lastName: '',
	createdAt: new Date().toISOString()
}
