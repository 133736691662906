import {
	CLEAR_PRINT_DATA,
	UPDATE_PRINT_STATUS,
	UPDATE_PRINT_DATA,
	CLEAR_PRINT_STATUS,
	UPDATE_DOWNLOAD_STATUS
} from '../actions/types'
import { PRINT_STATUS } from '../constants'

const initialState = {
	printData: null,
	printStatus: PRINT_STATUS.NO_PRINT,
	isDownloadFinished: true
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PRINT_DATA:
			return { ...state, printData: action.payload.data }
		case CLEAR_PRINT_DATA:
			return { ...state, printData: initialState.printData }
		case UPDATE_PRINT_STATUS:
			return { ...state, printStatus: action.payload.status }
		case CLEAR_PRINT_STATUS:
			return { ...state, printStatus: initialState.printStatus }
		case UPDATE_DOWNLOAD_STATUS:
			return { ...state, isDownloadFinished: action.payload.value }
		default:
			return state
	}
}

export default reducer

export const fileSelector = {
	getPrintData: (state = initialState) => state.printData,
	getPrintStatus: (state = initialState) => state.printStatus,
	getIsDownloadFinished: (state = initialState) => state.isDownloadFinished
}
