import {
	ADD_CHOSEN_TEMPLATE,
	CHANGE_CURRENT_SECTION_INDEX,
	CHANGE_TEMPLATE_BOARD_SHOW,
	CLEAR_CHOSEN_TEMPLATE,
	GET_DEFAULT_TEMPLATES,
	GET_DEFAULT_TEMPLATES_FAIL,
	GET_DEFAULT_TEMPLATES_SUCCESS,
	UPDATE_TEMPLATE_DRAFT,
	GET_ALL_TEMPLATES,
	GET_ALL_TEMPLATES_FAIL,
	GET_ALL_TEMPLATES_SUCCESS,
	GET_ALL_ACCESSIBLE_TEMPLATES_SUCCESS,
	GET_ALL_SECTION_TEMPLATES,
	GET_ALL_SECTION_TEMPLATES_FAIL,
	GET_ALL_SECTION_TEMPLATES_SUCCESS,
	GET_ALL_SECTION_TEMPLATE_CATEGORIES_SUCCESS,
	GET_ALL_SECTION_TEMPLATE_DETAILS_SUCCESS
} from '../actions/types'
import { templatesTransformer } from '../helpers/dataTransformer'

const initialState = {
	templates: [],
	allTemplates: [],
	allTemplateCategories: [],
	templatesChosen: [],
	isTemplateBoardShow: false,
	currentSectionIndex: -1,
	draft: {
		// Set the default to 2
		templateCategories: [2],
		templateId: -1
	},
	accessibleTemplateIds: [],
	allTemplateDetails: {}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_DEFAULT_TEMPLATES:
			return { ...state }
		case GET_DEFAULT_TEMPLATES_FAIL:
			return { ...state }
		case GET_DEFAULT_TEMPLATES_SUCCESS:
			return { ...state, templates: templatesTransformer(action.payload.data) }
		case GET_ALL_SECTION_TEMPLATES:
			return { ...state }
		case GET_ALL_SECTION_TEMPLATES_FAIL:
			return { ...state }
		case GET_ALL_SECTION_TEMPLATES_SUCCESS:
			return {
				...state,
				allTemplates: templatesTransformer(action.payload.data)
			}
		case GET_ALL_SECTION_TEMPLATE_CATEGORIES_SUCCESS:
			return {
				...state,
				allTemplateCategories: action.payload.data
			}
		case ADD_CHOSEN_TEMPLATE:
			return {
				...state,
				templatesChosen: [...state.templatesChosen, action.payload.templateId]
			}
		case CLEAR_CHOSEN_TEMPLATE:
			return {
				...state,
				templatesChosen: initialState.templatesChosen
			}
		case CHANGE_TEMPLATE_BOARD_SHOW:
			return { ...state, isTemplateBoardShow: action.payload.isOpen }
		case CHANGE_CURRENT_SECTION_INDEX:
			return { ...state, currentSectionIndex: action.payload.sectionIndex }
		case UPDATE_TEMPLATE_DRAFT:
			return {
				...state,
				draft: { ...state.draft, [action.payload.key]: action.payload.value }
			}
		case GET_ALL_TEMPLATES:
		case GET_ALL_TEMPLATES_FAIL:
			return { ...state }
		case GET_ALL_SECTION_TEMPLATE_DETAILS_SUCCESS:
			return { ...state, allTemplateDetails: action.payload.data }
		case GET_ALL_TEMPLATES_SUCCESS:
			return { ...state, templates: action.payload.data }
		case GET_ALL_ACCESSIBLE_TEMPLATES_SUCCESS: {
			return { ...state, accessibleTemplateIds: action.payload.data }
		}
		default:
			return state
	}
}

export default reducer

export const templatesSelector = {
	getTemplates: (state = initialState) => state.templates,
	getAllTemplates: (state = initialState) => state.allTemplates,
	getAllTemplateDetails: (state = initialState) => state.allTemplateDetails,
	getAllCategories: (state = initialState) => state.allTemplateCategories,
	getAllAccessibleTemplateIds: (state = initialState) =>
		state.accessibleTemplateIds,
	getTemplatesChosen: (state = initialState) => state.templatesChosen,
	getTemplateBoardShow: (state = initialState) => state.isTemplateBoardShow,
	getCurrentSectionIndex: (state = initialState) => state.currentSectionIndex,
	getTemplateCategories: (state = initialState) =>
		state.draft.templateCategories,
	getDraftTemplateId: (state = initialState) => state.draft.templateId
}
