import moment from 'moment'
import {
	CHANGE_ADVANCE_FILTER,
	CHANGE_SECTION_FILTER,
	CHANGE_SECTION_FILTER_ENABLED,
	CLEAR_ADVANCE_COMPARISON,
	CLEAR_ADVANCE_FILTER,
	CLEAR_METER_COMPARISON_RESULT,
	CLEAR_RESULT_DETAIL_FILTER,
	CLEAR_SECTION_FILTER,
	GET_COMPARISON_BY_ADVANCE_COMPARISON_SUCCESS,
	GET_COMPARISON_BY_ADVANCE_FILTER_SUCCESS,
	GET_COMPARISON_BY_OWN_SURVEY_SUCCESS,
	GET_COMPARISON_BY_SURVEY_SUCCESS,
	GET_METER_COMPARISON_SUCCESS,
	GET_RESULT_BY_SURVEY_ID,
	GET_RESULT_BY_SURVEY_ID_FAIL,
	GET_RESULT_BY_SURVEY_ID_SUCCESS,
	GET_RESULT_FILTERS_BY_SURVEY_ID_SUCCESS,
	GET_SECTION_FILTER_RESULT_SUCCESS,
	GET_SURVEY_COMPARISON_SUCCESS,
	GET_TAG_FILTER_COUNT_SUCCESS,
	UPDATE_RESULT_DETAIL,
	UPDATE_RESULT_DETAIL_FILTER,
	UPDATE_RESULT_DETAIL_STATUS,
	UPDATE_YEAR_FILTER,
	GET_SURVEY_ANSWERS,
	GET_SURVEY_ANSWERS_SUCCESS,
	GET_SURVEY_ANSWERS_FAIL,
	GET_ORGANISATION_RESULT,
	GET_ORGANISATION_RESULT_FAIL,
	GET_ORGANISATION_RESULT_SUCCESS,
	GET_ORGANISATION_SECTION_FILTER,
	GET_ORGANISATION_SECTION_FILTER_FAIL,
	GET_ORGANISATION_SECTION_FILTER_SUCCESS,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON_SUCCESS,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON_FAIL,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON,
	GET_RESULT_FILTERS_BY_TEMPLATE_ID_SUCCESS,
	GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_SUCCESS,
	GET_COMBINE_BY_OWN_SURVEY_SUCCESS
} from '../actions/types'
import { RESULT_DETAIL_STATE } from '../constants'
import {
	resultTransformer,
	organisationResultTransformer,
	combineResultTransformer
} from '../helpers/dataTransformer'
import {
	checkIfHasAgeFilter,
	getAgeFilter,
	getFilterYearRange
} from '../helpers/reducerHelper'

const initialState = {
	detailedResult: null,
	loading: false,
	metersChoose: [],
	tagFiltersChoose: [],
	ownSurveysChoose: [],
	meters: [],
	tagFilters: [],
	ownSurveys: [],
	isSectionFilterEnabled: false,
	sectionFilterSelection: '',
	sectionFilterOptions: [],
	sectionAdvanceComparison: [
		// {
		// 	name: 'ryhmän nimi 1',
		// 	identifiers: '',
		// 	selection: '',
		// 	type: '',
		// 	value: [],
		// 	filterYearLeft: new Date().getFullYear() - 1,
		// 	filterYearRight: new Date().getFullYear()
		// }
	],
	sectionAdvanceComparisonFilter: {
		selection: '',
		options: [],
		enabled: true
	},
	sectionAdvanceFilterSelection: [],
	sectionAdvanceFilterOptions: [],
	sectionAdvanceFilterGroupName: [],
	sectionFilterCompared: false,
	status: RESULT_DETAIL_STATE.DEFAULT,
	comparedMeter: [],
	hasAgeFilter: false,
	hasAnswererNumberFilter: false,
	yearFilter: {
		filterYearMin: new Date().getFullYear() - 1,
		filterYearMax: new Date().getFullYear(),
		filterYearLeft: new Date().getFullYear() - 1,
		filterYearRight: new Date().getFullYear()
	},
	answerTimeFilter: {
		answerTimeStart: null,
		answerTimeEnd: null,
		type: 'answerTime'
	},
	tagFilterCount: {
		organizations: null,
		surveys: null
	},
	surveyAnswers: [],
	organisationContent: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_RESULT_BY_SURVEY_ID:
			return { ...state }
		case GET_RESULT_BY_SURVEY_ID_FAIL:
			return { ...state }
		case GET_RESULT_BY_SURVEY_ID_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.DEFAULT,
				hasAgeFilter: checkIfHasAgeFilter(action.payload.data),
				hasAnswererNumberFilter: action.payload.data.hasAnswererNumbers
			}
		case GET_RESULT_FILTERS_BY_SURVEY_ID_SUCCESS:
			const { min: filterYearMin, max: filterYearMax } = getFilterYearRange(
				action.payload.data
			)
			const { miniumDate, maximumDate } =
				action.payload.data &&
				Array.isArray(action.payload.data.answerTimes) &&
				action.payload.data.answerTimes.length &&
				action.payload.data.answerTimes[0]
			return {
				...state,
				meters: action.payload.data.meters,
				ownSurveys: action.payload.data.ownSurveys,
				tagFilters: action.payload.data.tagFilter,
				yearFilter: {
					...state.yearFilter,
					filterYearMin,
					filterYearMax,
					filterYearLeft: filterYearMin,
					filterYearRight: filterYearMax
				},
				answerTimeFilter: {
					...state.answerTimeFilter,
					answerTimeStart: miniumDate
						? moment(miniumDate)
								.startOf('day')
								.toISOString()
						: null,
					answerTimeEnd: maximumDate
						? moment(maximumDate)
								.endOf('day')
								.toISOString()
						: null
				}
			}
		case GET_RESULT_FILTERS_BY_TEMPLATE_ID_SUCCESS: {
			const { min: filterYearMin, max: filterYearMax } = getFilterYearRange(
				action.payload.data
			)
			const { miniumDate, maximumDate } =
				action.payload.data &&
				Array.isArray(action.payload.data.answerTimes) &&
				action.payload.data.answerTimes.length &&
				action.payload.data.answerTimes[0]
			return {
				...state,
				tagFilters: action.payload.data.tagFilter,
				ownSurveys: action.payload.data.ownSurveys,
				yearFilter: {
					...state.yearFilter,
					filterYearMin,
					filterYearMax,
					filterYearLeft: filterYearMin,
					filterYearRight: filterYearMax
				},
				answerTimeFilter: {
					...state.answerTimeFilter,
					answerTimeStart: miniumDate
						? moment(miniumDate)
								.startOf('day')
								.toISOString()
						: null,
					answerTimeEnd: maximumDate
						? moment(maximumDate)
								.endOf('day')
								.toISOString()
						: null
				}
			}
		}
		case UPDATE_RESULT_DETAIL_FILTER:
			return {
				...state,
				[action.payload.filter]: action.payload.value
			}
		case CLEAR_RESULT_DETAIL_FILTER:
			return {
				...state,
				metersChoose: initialState.metersChoose,
				tagFiltersChoose: initialState.tagFiltersChoose,
				ownSurveysChoose: initialState.ownSurveysChoose,
				// yearFilter: initialState.yearFilter,
				tagFilterCount: initialState.tagFilterCount
			}
		case GET_SURVEY_COMPARISON_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.SURVEY_COMPARISON
			}
		case CHANGE_SECTION_FILTER_ENABLED:
			return {
				...state,
				isSectionFilterEnabled: action.payload.value
			}
		case CHANGE_SECTION_FILTER:
			return {
				...state,
				[action.payload.key]: action.payload.value
			}
		case CHANGE_ADVANCE_FILTER:
			return {
				...state,
				[action.payload.key]: action.payload.value
			}
		case CLEAR_SECTION_FILTER:
			return {
				...state,
				sectionFilterOptions: initialState.sectionFilterOptions,
				sectionFilterSelection: initialState.sectionFilterSelection,
				sectionFilterCompared: initialState.sectionFilterCompared
			}
		case CLEAR_ADVANCE_FILTER:
			return {
				...state,
				sectionAdvanceFilterOptions: initialState.sectionAdvanceFilterOptions,
				sectionAdvanceFilterSelection:
					initialState.sectionAdvanceFilterSelection,
				sectionAdvanceFilterGroupName:
					initialState.sectionAdvanceFilterGroupName
			}
		case CLEAR_ADVANCE_COMPARISON:
			return {
				...state,
				sectionAdvanceComparison: initialState.sectionAdvanceComparison,
				sectionAdvanceComparisonFilter:
					initialState.sectionAdvanceComparisonFilter
			}
		case GET_SECTION_FILTER_RESULT_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.SECTION_FILTER
			}
		case UPDATE_RESULT_DETAIL_STATUS:
			return {
				...state,
				status: action.payload.status
			}
		case GET_COMPARISON_BY_SURVEY_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.SURVEY_FILTER
			}
		case GET_COMPARISON_BY_ADVANCE_FILTER_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.ADVANCE_FILTER
			}
		case GET_COMPARISON_BY_ADVANCE_COMPARISON_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.ADVANCE_COMPARISON
			}
		case GET_COMPARISON_BY_OWN_SURVEY_SUCCESS:
			return {
				...state,
				detailedResult: resultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.SURVEY_COMPARISON
			}
		case GET_COMBINE_BY_OWN_SURVEY_SUCCESS:
			return {
				...state,
				detailedResult: combineResultTransformer(action.payload.data),
				status: RESULT_DETAIL_STATE.COMBINE
			}
		case UPDATE_RESULT_DETAIL:
			return { ...state, [action.payload.key]: action.payload.value }
		case GET_METER_COMPARISON_SUCCESS:
			if (
				state.comparedMeter.some(
					meter => meter.meterId === action.payload.data.meterId
				)
			) {
				return {
					...state,
					comparedMeter: state.comparedMeter.map(meter => {
						if (meter.meterId === action.payload.data.meterId) {
							return {
								meterId: action.payload.data.meterId,
								value: action.payload.data.data
							}
						}
						return meter
					})
				}
			} else {
				return {
					...state,
					comparedMeter: [
						...state.comparedMeter,
						{
							meterId: action.payload.data.meterId,
							value: action.payload.data.data
						}
					]
				}
			}
		case CLEAR_METER_COMPARISON_RESULT:
			return {
				...state,
				comparedMeter: initialState.comparedMeter
			}
		case UPDATE_YEAR_FILTER:
			return {
				...state,
				yearFilter: {
					...state.yearFilter,
					[action.payload.key]: action.payload.value
				}
			}
		case GET_TAG_FILTER_COUNT_SUCCESS:
			return {
				...state,
				tagFilterCount: {
					organizations: action.payload.data.organizations,
					surveys: action.payload.data.surveys
				}
			}
		case GET_SURVEY_ANSWERS_SUCCESS:
			return {
				...state,
				surveyAnswers: action.payload.data
			}
		case GET_ORGANISATION_SECTION_FILTER:
		case GET_ORGANISATION_SECTION_FILTER_COMPARISON:
		case GET_ORGANISATION_RESULT:
			return {
				...state,
				loading: true
			}

		case GET_ORGANISATION_RESULT_SUCCESS:
			return {
				...state,
				organisationContent: action.payload.data,
				loading: false
			}
		case GET_ORGANISATION_SECTION_FILTER_FAIL:
		case GET_ORGANISATION_SECTION_FILTER_COMPARISON_FAIL:
		case GET_ORGANISATION_RESULT_FAIL:
			return {
				...state,
				loading: false
			}
		case GET_ORGANISATION_SECTION_FILTER_COMPARISON_SUCCESS:
			return {
				...state,
				organisationContent: action.payload.data,
				status: RESULT_DETAIL_STATE.SURVEY_FILTER
			}
		case GET_ORGANISATION_ADVANCED_FILTER_COMPARISON_SUCCESS:
			return {
				...state,
				organisationContent: action.payload.data,
				status: RESULT_DETAIL_STATE.ADVANCE_FILTER
			}
		case GET_ORGANISATION_SECTION_FILTER_SUCCESS:
			return {
				...state,
				organisationContent: action.payload.data,
				status: RESULT_DETAIL_STATE.SECTION_FILTER
			}
		default:
			return state
	}
}

export default reducer

export const resultDetailSelector = {
	getDetailResult: (state = initialState) => state.detailedResult,
	getMeters: (state = initialState) => state.meters,
	getTagFilters: (state = initialState) => state.tagFilters,
	getOwnSurveys: (state = initialState) => state.ownSurveys,
	getMetersChoose: (state = initialState) => state.metersChoose,
	getTagFiltersChoose: (state = initialState) => state.tagFiltersChoose,
	getOwnSurveysChoose: (state = initialState) => state.ownSurveysChoose,
	getIsSectionFilterEnabled: (state = initialState) =>
		state.isSectionFilterEnabled,
	getSectionFilterSelection: (state = initialState) =>
		state.sectionFilterSelection,
	getSectionFilterOptions: (state = initialState) => state.sectionFilterOptions,
	getSectionAdvanceFilterSelection: (state = initialState) =>
		state.sectionAdvanceFilterSelection,
	getSectionAdvanceComparison: (state = initialState) =>
		state.sectionAdvanceComparison,
	getSectionAdvanceComparisonFilter: (state = initialState) =>
		state.sectionAdvanceComparisonFilter,
	getSectionAdvanceFilterOptions: (state = initialState) =>
		state.sectionAdvanceFilterOptions,
	getSectionAdvanceFilterGroupName: (state = initialState) =>
		state.sectionAdvanceFilterGroupName,
	getStatus: (state = initialState) => state.status,
	getSectionFilterCompared: (state = initialState) =>
		state.sectionFilterCompared,
	getComparedMeter: (state = initialState) => state.comparedMeter,
	getHasAgeFilter: (state = initialState) => state.hasAgeFilter,
	getHasAnswererNumberFilter: (state = initialState) =>
		state.hasAnswererNumberFilter,
	getAnswerTimeFilter: (state = initialState) => state.answerTimeFilter,
	getFilterYearMin: (state = initialState) => state.yearFilter.filterYearMin,
	getFilterYearMax: (state = initialState) => state.yearFilter.filterYearMax,
	getFilterYearLeft: (state = initialState) => state.yearFilter.filterYearLeft,
	getFilterYearRight: (state = initialState) =>
		state.yearFilter.filterYearRight,
	getTagFilterOrganisationCount: (state = initialState) =>
		state.tagFilterCount.organizations,
	getTagFilterSurveyCount: (state = initialState) =>
		state.tagFilterCount.surveys,
	getOrganisationContent: (state = initialState) => state.organisationContent
}
