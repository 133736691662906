//Reordering section based on numerical value and ordinal
import { sortBy } from './sortHelper'
import { checkIfBirthYearSection } from './functionHelpers'
import { BIRTHYEAR_SECTION_TITLE } from '../constants'

export const reorderSection = sections => {
	return sortBy(sections, 'ordinal').map(section => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		delete newSection.id
		newSection.questions = section.questions.map(question => {
			const newQuestion = { ...question }
			delete newQuestion.id
			newQuestion.rows = sortBy(question.rows, 'ordinal')
			newQuestion.columns = sortBy(question.columns, 'ordinal')
			return newQuestion
		})
		return newSection
	})
}

export const sortTagCategories = categories => {
	Object.keys(categories).forEach((category, index) => {
		// If it's not the last category, because we don't want to sort that by title, instead by ordinal which comes from backend
		if (index !== 2) {
			categories[category].sort((a, b) => {
				const titleA = a.title.toLowerCase()
				const titleB = b.title.toLowerCase()
				if (titleA < titleB) {
					return -1
				}
				if (titleA > titleB) {
					return 1
				}
				return 0
			})
		}
	})
	return categories
}

export const markNotificationAsSeenTransformer = (
	notifications,
	newNotification
) => {
	return notifications.map(notification => {
		if (notification.id === newNotification.id) {
			notification.seen = newNotification.seen
		}
		return notification
	})
}

export const checkIfHasAgeFilter = result => {
	const birthYearSection = result.content.sections.find(
		section => section.title.toLowerCase() === BIRTHYEAR_SECTION_TITLE
	)
	if (!!birthYearSection) {
		return birthYearSection.questions.some(question => {
			return question.rows[0].texts && question.rows[0].texts.length > 0
		})
	}
	return false
}

const filterAndMapFilterYear = (array = []) => {
	return array.reduce((years, filter) => {
		if (filter.years) {
			const yearsSplitter = filter.years.split(',')
			return [...years, ...yearsSplitter.map(year => Number(year))]
		}
		return years
	}, [])
}

export const getFilterYearRange = filters => {
	const metersYearFilter = filterAndMapFilterYear(filters.meters)
	const ownSurveysYearFilter = filterAndMapFilterYear(filters.ownSurveys)
	const tagYearFilter = filterAndMapFilterYear(filters.tagFilter)
	const filterYearArray = [
		...metersYearFilter,
		...ownSurveysYearFilter,
		...tagYearFilter
	].sort()
	if (filterYearArray.length > 0) {
		return {
			min: filterYearArray[0],
			max: filterYearArray[filterYearArray.length - 1]
		}
	}
	return {
		min: new Date().getFullYear() - 1,
		max: new Date().getFullYear()
	}
}
