// For changing links to a tags
import anchorme from 'anchorme'
import sanitizeHtml from 'sanitize-html'

import i18n from '../i18n'
import { emailValidator } from './validator'
import { store } from '../config/store'
import {
	BIRTHYEAR_SECTION_TITLE,
	COMBINE_FILTER_LIMIT,
	FILTER_LIMIT,
	QUESTION_TYPE,
	SPECIAL_SECTION_TEMPLATE_IDS,
	SPECIAL_SURVEY_REVERSE_IDENTIFIER,
	SPECIAL_SURVEY_ROWS
} from '../constants'

import _ from 'lodash'
//Sorting function

export const sort = (array, key, order = 'asc') => {
	//Two option: asc or desc
	if (array == null || array.length === 0) return []
	return [...array].sort((item1, item2) => {
		return order === 'asc'
			? compareAsc(item1[key], item2[key])
			: compareDesc(item1[key], item2[key])
	})
}

//Sorting with function
export const sortWith = (arr, comparedFunc) => {
	if (arr == null || arr.length === 0) return []
	return [...arr].sort(comparedFunc)
}

const compareAsc = (a, b) => {
	return a - b
}

const compareDesc = (a, b) => {
	return b - a
}

export const moveItemInArray = (arr, old_index, new_index) => {
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
	return arr // for testing
}

//Is page break enabled?

export const pageBreakHandler = (array, index) => {
	let pageBreakEnabled = false,
		deletePageBreakEnabled = false,
		showPageBreak = false
	const pageArray = array.map(section => section.page)
	const pageMin = Math.min(...pageArray)
	const pageMax = Math.max(...pageArray)
	// if (index === 0 && )
	if (array.length > 1) {
		// If it's last element
		if (index >= array.length - 1) {
			// showPageBreak = true
			//Keep default
		} else if (index === 0) {
			if (pageMin === 2) {
				deletePageBreakEnabled = true
				showPageBreak = true
			} else {
				pageBreakEnabled = !!(
					array[index + 1] &&
					array[index].page === 1 &&
					array[index].page === array[index + 1].page
				)
				showPageBreak = !!(array[index].page < array[index + 1].page)
				deletePageBreakEnabled = !!(array[index].page < array[index + 1].page)
			}
		} else {
			pageBreakEnabled =
				!!array[index + 1] && array[index].page === array[index + 1].page
			showPageBreak = !!(
				array[index].page >= array[index - 1].page &&
				array[index].page < array[index + 1].page
			)
			deletePageBreakEnabled = !!(
				array[index].page >= array[index - 1].page &&
				array[index].page < array[index + 1].page
			)
		}
	}
	// If array length = 0, both should be disabled
	return {
		pageBreakEnabled,
		showPageBreak,
		deletePageBreakEnabled
	}
}

// Get locale
export const getCurrentLanguage = () => i18n.language

// Get max index
export const maxBy = (array, comparedKey) => {
	let index = 0
	let max = array[0][comparedKey]
	array.map((item, i) => {
		if (item[comparedKey] >= max) {
			max = item[comparedKey]
			index = i
		}
	})
	return index
}

/**
 *
 * @param array
 * @param comparedKey
 * @returns {number}
 */
// Get max index
export const minBy = (array, comparedKey) => {
	let index = 0
	let min = array[0][comparedKey]
	array.map((item, i) => {
		if (item[comparedKey] <= min) {
			min = item[comparedKey]
			index = i
		}
	})
	return index
}

//Modifying answer before sending
export const modifyAnswer = sections => {
	try {
		return sections.map(section => {
			const cloneSection = { ...section }
			cloneSection.questions = section.questions.map(question => {
				const cloneQuestion = { ...question }
				if (cloneQuestion.answer) {
					// TODO need to check why answer is empty!
					cloneQuestion.answer = question.answer.reduce((answers, item) => {
						if (item.row && item.column) {
							const cloneItem = { ...item }
							cloneItem.row = question.rows[item.row - 1].id
							cloneItem.column = question.columns[item.column - 1].id
							answers.push(cloneItem)
						}
						return answers
					}, [])
				}
				return cloneQuestion
			})
			return cloneSection
		})
	} catch (error) {
		throw error
	}
}

//Refactor content copy from excel
export const refactorContent = content => {
	const users = content.split('\n')
	return users.map(user => {
		let userArray = user.split(/[ \t]/)
		let customer = {
			email: '',
			firstName: '',
			lastName: '',
			answererNumber: null
		}
		const isValidEmail = emailValidator(userArray[0].trim())
		if (isValidEmail) {
			customer.email = userArray[0].trim()
			if (userArray[1]) {
				customer.firstName = userArray[1].trim()
			}
			if (userArray[2]) {
				customer.lastName = userArray[2].trim()
			}
			if (userArray[3]) {
				customer.answererNumber = userArray[3].trim()
			}
		} else {
			throw new Error(i18n.t('error.valid email'))
		}
		return customer
	})
}

//calculate median
export const getMedian = arr => {
	const arrLength = arr.length
	const sortedArr = arr.sort((a, b) => a - b)
	if (arrLength % 2 === 0) {
		const higherIndex = arrLength / 2
		const lowerIndex = higherIndex - 1
		return (sortedArr[lowerIndex] + sortedArr[higherIndex]) / 2
	} else {
		//Should be middle index - 1 === 0
		const middleIndex = Math.floor(arrLength / 2)
		return sortedArr[middleIndex]
	}
}

//Count by
export const countBy = (arr, value) => {
	return arr.reduce((acc, currVal) => {
		if (currVal === value) return acc + 1
		return acc
	}, 0)
}

//Check whether the survey is created
export const checkIfSurveyCreated = () => {
	const appState = store.getState()
	return appState.survey.draft.id !== -1
}

//Check if question main data is column
export const isColumnData = questionTypeId => {
	return (
		questionTypeId === QUESTION_TYPE.LINEAR_SCALE_HORIZONTAL ||
		questionTypeId === QUESTION_TYPE.LINEAR_SCALE ||
		questionTypeId === QUESTION_TYPE.RADIO_HORIZONTAL ||
		questionTypeId === QUESTION_TYPE.CHECKBOX_HORIZONTAL
	)
}

//Updating Filter
/**
 *
 * @param {[String]} array
 * @param {String} filter
 */
export const updateFilter = (array, filter, isCombine = false) => {
	const meterIndex = array.findIndex(item => item === filter)
	const clone = [...array]
	if (meterIndex === -1) {
		if (clone.length < FILTER_LIMIT) {
			clone.push(filter)
		} else if (isCombine && clone.length < COMBINE_FILTER_LIMIT) {
			clone.push(filter)
		} else {
			clone.splice(clone.length - 1, 1, filter)
		}
	} else {
		clone.splice(meterIndex, 1)
	}

	return clone
}
/**
 *
 * @param {[String]} array
 * @param {String} filter
 */

export const updateMeterFilter = (array, filter) => {
	const meterIndex = array.findIndex(item => item === filter)
	let clone = [...array]
	if (meterIndex === -1) {
		clone.push(filter)
		// If choose gender, de-select education
		if (filter === 'women_27' || filter === 'men_27') {
			clone = clone.filter(
				choice =>
					choice !== '4274_27' && choice !== '4275_27' && choice !== '4276_27'
			)
		} else if (filter === 'women_36' || filter === 'men_36') {
			clone = clone.filter(
				choice =>
					choice !== '4288_36' && choice !== '4289_36' && choice !== '4290_36'
			)
		}
	} else {
		clone.splice(meterIndex, 1)
	}

	return clone
}

/**
 *
 * @param {[[]]} array
 * @param {String} filter
 * @param {Number} filterGroupIndex
 * @param {Number} [filterIndex]
 */
export const updateFilterDeepArray = (
	array,
	filter,
	filterGroupIndex,
	filterIndex
) => {
	const clone = _.cloneDeep(array)
	let cloneArray = clone[filterGroupIndex] || []

	if (typeof filterIndex === 'undefined') {
		cloneArray = updateFilter(cloneArray, filter)
	} else {
		cloneArray[filterIndex] = filter
	}
	clone[filterGroupIndex] = cloneArray

	return clone
}

/**
 * @param {[{}]} array
 * @param {Number} index
 * @param {String} key
 * @param {String|Array} [newValue]
 */
export const updateFilterDeepObject = (array, index, key, newValue) => {
	const cloneArray = _.cloneDeep(array)
	const currentFilter = cloneArray[index]
	if (Array.isArray(newValue)) {
		currentFilter[key] = [...newValue]
	} else {
		currentFilter[key] = newValue
	}

	return cloneArray
}

/**
 *
 * @param {[{}]} array
 * @param {{}} newElement
 * @param {Number|[Number]} [filterIndexes]
 */
export const updateGroupFilter = (array, newElement, filterIndexes) => {
	const cloneArray = _.cloneDeep(array)
	if (typeof filterIndexes === 'undefined') {
		cloneArray.push(newElement)
	} else if (typeof filterIndexes === 'number') {
		cloneArray.splice(filterIndexes, 1)
	} else if (Array.isArray(filterIndexes)) {
		filterIndexes.forEach(index => cloneArray.splice(index, 1))
	}

	return cloneArray
}
/**
 *
 * @param {[{selection:Number,filterId:Number}]} options
 * @param {Number} filterGroupIndex
 * @param {Number} filterIndex
 */
export const filterIndexBasedOnGroup = (
	options,
	filterGroupIndex,
	filterIndex
) => {
	const filterByGroup = options.filter(
		option => option.filterId === filterGroupIndex
	)
	const indexBasedOnGroup = options.reduce((acc, option, index) => {
		if (
			option.filterId === filterGroupIndex &&
			filterByGroup[filterIndex].selection === option.selection
		) {
			return index
		}
		return acc
	}, 0)
	return indexBasedOnGroup
}

export const isIE = () => {
	const ua = window.navigator.userAgent
	const msie = ua.indexOf('MSIE ')
	return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) // If Internet Explorer, return version number
}

//Scroll to top
export const scrollToTop = el => {
	if (isIE()) return el.scrollIntoView()
	else return window.scrollTo(0, 0)
}

//Editing item in array
export const editItemInArray = (array, newItem, index) => {
	const clone = [...array]
	clone.splice(index, 1, newItem)
	return clone
}
//Scroll into view
export const scrollIntoView = el => {
	if (isIE()) return el.current.scrollIntoView(true)
	else return window.scrollTo(0, el.current.offsetTop - 120)
}

//Check if birthyear section
export const checkIfBirthYearSection = (sections, value) => {
	return sections.find(
		section =>
			section.id === value &&
			section.title.toLowerCase() === BIRTHYEAR_SECTION_TITLE
	)
}

export const roundTo2Decimals = number =>
	Math.round((number + Number.EPSILON) * 100) / 100

/**
 * Check if year is matching with filter year
 * @param yearString
 * @param filterYear
 * @return {*}
 */
export const checkYearMatchFilterYear = (yearString, filterYear) => {
	if (typeof filterYear === 'string') {
		return yearString.split(',').includes(filterYear.toString())
	} else if (Array.isArray(filterYear)) {
		return yearString
			.split(',')
			.some(
				year => Number(year) >= filterYear[0] && Number(year) <= filterYear[1]
			)
	}
	return false
}

/**
 * Get mark for slider
 * @return {*}
 */

export const getMarkForSlider = (value, min, max) => {
	if (Array.isArray(value)) {
		if (value[0] === value[value.length - 1]) {
			return getMarkForSingleValueSlider(value[0], min, max)
		}
		if (value[0] === min && value[value.length - 1] === max) {
			return [
				{
					value: min,
					label: min
				},
				{
					value: max,
					label: max
				}
			]
		} else if (value[0] === min) {
			return [
				{
					value: min,
					label: min
				},
				{
					value: value[value.length - 1],
					label: value[value.length - 1]
				},
				{
					value: max,
					label: max
				}
			]
		} else if (value[value.length - 1] === max) {
			return [
				{
					value: min,
					label: min
				},
				{
					value: value[0],
					label: value[0]
				},
				{
					value: value[value.length - 1],
					label: value[value.length - 1]
				}
			]
		}
		return [
			{
				value: min,
				label: min
			},
			{
				value: value[0],
				label: value[0]
			},
			{
				value: value[1],
				label: value[1]
			},
			{
				value: max,
				label: max
			}
		]
	} else {
		return getMarkForSingleValueSlider(value, min, max)
	}
}

const getMarkForSingleValueSlider = (singleValue, min, max) => {
	if (singleValue === min) {
		return [
			{
				value: singleValue,
				label: singleValue
			},
			{
				value: max,
				label: max
			}
		]
	} else if (singleValue === max) {
		return [
			{
				value: min,
				label: min
			},
			{
				value: singleValue,
				label: singleValue
			}
		]
	}
	return [
		{
			value: min,
			label: min
		},
		{
			value: singleValue,
			label: singleValue
		},
		{
			value: max,
			label: max
		}
	]
}

export const openLinkInNewTab = link => {
	window.open(link, '_blank')
}

/**
 *
 * For checking if language has all sections with translations
 *
 * @param {*} draftSections
 * @returns boolean
 */
export const isTranslationLanguageSaveble = draftSections => {
	return !draftSections
		.map(canSectionTranslationBeSaved)
		.some(val => val === false)
}
/**
 *
 * For checking if language has all sections with translations for template
 *
 * @param {*} draftSections
 * @returns boolean
 */
export const isTranslationLanguageSavebleForTemplate = (
	draftSections,
	isTemplate
) => {
	return !draftSections
		.map(section => canSectionTranslationBeSaved(section, isTemplate))
		.some(val => val === false)
}

/**
 *
 * Checks if a section is missing translations
 *
 * @param {*} section
 * @returns Boolean
 */

export const canSectionTranslationBeSaved = (section, isTemplate) => {
	if (section.title) {
		if (section.title && !section.translationText) {
			return false
		}
	}
	if (isTemplate && section.tooltip) {
		if (section.tooltip.fi && !section.tooltip.translationText) {
			return false
		}
	}
	if (Array.isArray(section.questions)) {
		for (const question of section.questions) {
			// const questionTranslation =
			// 	Array.isArray(question.questionTranslation) &&
			// 	question.questionTranslation.find(
			// 		questionTrans => questionTrans.language === selectedLanguage
			// 	)

			if (question.text && question.translationText) {
				return false
			}
			if (Array.isArray(question.rows)) {
				for (const row of question.rows) {
					// const rowTranslation =
					// 	Array.isArray(row.questionRowTranslation) &&
					// 	row.questionRowTranslation.find(
					// 		rowTrans => rowTrans.language === selectedLanguage
					// 	)
					if (row.textualValue && !row.translationText) {
						return false
					}
				}
			}
			if (Array.isArray(question.columns)) {
				for (const column of question.columns) {
					// const columnTranslation =
					// 	Array.isArray(column.questionColumnTranslation) &&
					// 	column.questionColumnTranslation.find(
					// 		columnTrans => columnTrans.language === selectedLanguage
					// 	)
					if (column.textualValue && !column.translationText) {
						return false
					}
				}
			}
		}
	}
	return true
}
/**
 *
 * Checks if a section is missing translations or has original translations
 *
 * @param {*} section
 * @returns Boolean
 */

export const canSectionTranslationBeSavedWithOriginalChecks = (
	section,
	isTemplate,
	selectedLanguage
) => {
	if (section.title) {
		const sectionTranslation =
			section.sectionTranslation &&
			section.sectionTranslation.find(
				sectionTrans => sectionTrans.language === selectedLanguage
			)

		if (sectionTranslation && sectionTranslation.originalTranslationId) {
			return false
		}

		if (section.title && !section.translationText) {
			return false
		}
	}
	if (isTemplate && section.tooltip) {
		if (section.tooltip.fi && !section.tooltip.translationText) {
			return false
		}
	}
	if (Array.isArray(section.questions)) {
		for (const question of section.questions) {
			// const questionTranslation =
			// 	Array.isArray(question.questionTranslation) &&
			// 	question.questionTranslation.find(
			// 		questionTrans => questionTrans.language === selectedLanguage
			// 	)
			if (question.originalTranslationId) {
				return false
			}

			if (question.text && question.translationText) {
				return false
			}
			if (Array.isArray(question.rows)) {
				for (const row of question.rows) {
					// const rowTranslation =
					// 	Array.isArray(row.questionRowTranslation) &&
					// 	row.questionRowTranslation.find(
					// 		rowTrans => rowTrans.language === selectedLanguage
					// 	)
					if (row.originalTranslationId) {
						return false
					}
					if (row.textualValue && !row.translationText) {
						return false
					}
				}
			}
			if (Array.isArray(question.columns)) {
				for (const column of question.columns) {
					// const columnTranslation =
					// 	Array.isArray(column.questionColumnTranslation) &&
					// 	column.questionColumnTranslation.find(
					// 		columnTrans => columnTrans.language === selectedLanguage
					// 	)
					if (column.originalTranslationId) {
						return false
					}
					if (column.textualValue && !column.translationText) {
						return false
					}
				}
			}
		}
	}
	return true
}

/**
 * Adds translations for all of the questions if available
 *
 * @param {*} sections
 * @returns Array of sections with translations data
 */

export const mapTranslations = (sections, selectedLanguage) => {
	const clonedSections = _.cloneDeep(sections)
	const grouped = clonedSections.map(section => {
		if (section.tooltip && Array.isArray(section.tooltip.translation)) {
			const foundTooltipTranslation = section.tooltip.translation.find(
				tooltipTrans => tooltipTrans.language === selectedLanguage
			)

			if (foundTooltipTranslation) {
				section.tooltip.translationText = foundTooltipTranslation.text || ''
				section.tooltip.translationId = foundTooltipTranslation.id
				section.tooltip.originalTranslationId =
					foundTooltipTranslation.originalTranslationId
			} else {
				section.tooltip.translationText = ''
				section.tooltip.translationId = null
			}
		} else if (
			section.meter &&
			section.meter.tooltip &&
			Array.isArray(section.meter.tooltip.translation)
		) {
			const foundTooltipTranslation = section.meter.tooltip.translation.find(
				tooltipTrans => tooltipTrans.language === selectedLanguage
			)

			if (foundTooltipTranslation) {
				section.meter.tooltip.translationText =
					foundTooltipTranslation.text || ''
				section.meter.tooltip.translationId = foundTooltipTranslation.id
				section.meter.tooltip.originalTranslationId =
					foundTooltipTranslation.originalTranslationId
			} else {
				section.meter.tooltip.translationText = ''
				section.meter.tooltip.translationId = null
			}
		} else if (
			section.tooltip &&
			section.tooltip.translation &&
			section.tooltip.translation.language === selectedLanguage
		) {
			section.tooltip.translationText = section.tooltip.translation.text || ''
			section.tooltip.translationId = section.tooltip.translation.id
		} else {
			if (section.tooltip) {
				section.tooltip.translationText = ''
			}
		}
		if (section.meter && Array.isArray(section.meter.sectionTranslation)) {
			const foundTranslation = section.meter.sectionTranslation.find(
				trans => trans.language === selectedLanguage
			)
			if (foundTranslation) {
				section.translationText = foundTranslation.title || ''
				section.translationId = foundTranslation.id
				section.originalTranslationId = foundTranslation.originalTranslationId
			}
		}
		if (Array.isArray(section.sectionTranslation)) {
			const foundTranslation = section.sectionTranslation.find(
				trans => trans.language === selectedLanguage
			)
			if (foundTranslation) {
				section.translationText = foundTranslation.title || ''
				section.translationId = foundTranslation.id
				section.originalTranslationId = foundTranslation.originalTranslationId
			}
		} else if (
			section.sectionTranslation &&
			section.sectionTranslation.language === selectedLanguage
		) {
			section.translationText = section.sectionTranslation.title || ''
			section.translationId = section.sectionTranslation.id
		} else {
			section.translationText = ''
		}

		if (Array.isArray(section.questions)) {
			section.questions.map(question => {
				if (Array.isArray(question.questionTranslation)) {
					if (question.questionTranslation) {
						const foundTranslation = question.questionTranslation.find(
							trans => trans.language === selectedLanguage
						)
						if (foundTranslation) {
							question.translationText = foundTranslation.title || ''
							question.translationId = foundTranslation.id
							question.originalTranslationId =
								foundTranslation.originalTranslationId
						} else if (
							question.questionTranslation &&
							question.questionTranslation.language === selectedLanguage
						) {
							question.translationText =
								question.questionTranslation.title || ''
							question.translationId = question.questionTranslation.id
						}
					} else {
						question.translationText = ''
					}
				}
				if (Array.isArray(question.rows)) {
					question.rows.map(row => {
						if (Array.isArray(row.questionRowTranslation)) {
							const foundTranslation = row.questionRowTranslation.find(
								trans => trans.language === selectedLanguage
							)
							if (foundTranslation) {
								row.translationText = foundTranslation.title || ''
								row.translationId = foundTranslation.id
								row.originalTranslationId =
									foundTranslation.originalTranslationId
							} else if (
								row.questionRowTranslation &&
								row.questionRowTranslation.language === selectedLanguage
							) {
								row.translationText = row.questionRowTranslation.title || ''
								row.translationId = row.questionRowTranslation.id
							} else {
								row.translationText = ''
							}
						} else {
							row.translationText = ''
						}
						return row
					})
				}
				if (Array.isArray(question.columns)) {
					question.columns.map(column => {
						if (Array.isArray(column.questionColumnTranslation)) {
							const foundTranslation = column.questionColumnTranslation.find(
								trans => trans.language === selectedLanguage
							)
							if (foundTranslation) {
								column.translationText = foundTranslation.title || ''
								column.translationId = foundTranslation.id
								column.originalTranslationId =
									foundTranslation.originalTranslationId
							} else if (
								column.questionColumnTranslation &&
								column.questionColumnTranslation.language === selectedLanguage
							) {
								column.translationText =
									column.questionColumnTranslation.title || ''
								column.translationId = column.questionColumnTranslation.id
							} else {
								column.translationText = ''
							}
						} else {
							column.translationText = ''
						}
						return column
					})
				}
				return question
			})
		}

		return section
	})
	return grouped
}

export const createDictionaryWithProperty = (array, property) =>
	array.reduce((all, curr) => {
		all[curr[property]] = curr
		return all
	}, {})

export const replaceLinksInText = text =>
	sanitizeHtml(
		anchorme({
			input: text,
			options: {
				attributes: {
					target: '_blank'
				}
			}
		})
	)

export const calculateColumnValues = answers => {
	if (Array.isArray(answers)) {
		return answers.reduce(
			(totalValues, value) => {
				if (!isNaN(Number(value.column))) {
					totalValues[value.column - 1]++
				}
				return totalValues
			},
			[0, 0, 0, 0]
		)
	}
	return []
}

export const rowBackgroundColor = (index, isSpecial, isReversed) => {
	if (!isSpecial) {
		return index % 2 === 0 ? '#ededed' : 'white'
	} else {
		switch (index) {
			case 0:
				return isReversed ? '#7ED957' : '#FF1717'
			case 1:
				return isReversed ? '#FFDF59' : '#FF914C'
			case 2:
				return isReversed ? '#FF914C' : '#FFDF59'
			case 3:
				return isReversed ? '#FF1717' : '#7ED957'
			default:
				break
		}
	}
}

/**
 *
 * Calculates result for emotional scale
 *
 * @param {*} sections
 * @returns Object with red and green weights as number
 */
export const getScaleValues = sections => {
	const scaleValues = { red: 0, green: 0 }
	if (Array.isArray(sections) && sections.length) {
		sections.forEach(section => {
			if (Array.isArray(section.questions)) {
				section.questions.forEach(question => {
					if (Array.isArray(question.rows)) {
						const foundSpecialRow = question.rows.find(row =>
							SPECIAL_SURVEY_ROWS.includes(row.identifier)
						)
						if (foundSpecialRow) {
							const isReversed = Boolean(
								question.rows.find(row =>
									SPECIAL_SURVEY_REVERSE_IDENTIFIER.includes(row.identifier)
								)
							)
							if (Array.isArray(question.answer) && question.answer.length) {
								if (isReversed) {
									const [first, second, third, fourth] = calculateColumnValues(
										question.answer
									).reverse()
									scaleValues.red += first + second
									scaleValues.green += third + fourth
								} else {
									const [first, second, third, fourth] = calculateColumnValues(
										question.answer
									)
									scaleValues.red += first + second
									scaleValues.green += third + fourth
								}
							}
						}
					}
				})
			}
		})
	}
	return scaleValues
}

export const hasSpecialRows = sections => {
	let hasSpecialRows = false
	if (Array.isArray(sections) && sections.length) {
		sections.forEach(section => {
			if (
				section.meter &&
				SPECIAL_SECTION_TEMPLATE_IDS.includes(section.meter.id)
			) {
				hasSpecialRows = true
			}
		})
	}
	return hasSpecialRows
}

export const getTemplatesAvailableLanguages = (categories = []) => {
	const allTemplates = categories
		.map(category => {
			if (Array.isArray(category.templates)) {
				return category.templates
			} else {
				;[]
			}
		})
		.flat(1)
	const mappedTemplates = allTemplates.reduce((combined, curr) => {
		if (curr.content) {
			combined[curr.id] = curr.content.availableSurveyLanguages
		}
		return combined
	}, {})
	return mappedTemplates
}
