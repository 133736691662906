/**
 * Root client file
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// React stuffs
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/reflect'
import 'core-js/es/object'
import 'core-js/features/number/is-nan'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// history from store
import { history, persistor, store } from './config/store'
import { MuiThemeProvider } from '@material-ui/core/styles'

// Styling
import './styles/index.scss'
// Components
import App from './App'
//i18n
import './i18n'
import './polyfill'
import { theme } from './config/theme'
import { updatePrintData, updatePrintStatus } from './actions/file'
import {
	updateResultDetailFilter,
	updateResultDetailStatus
} from './actions/resultDetail'
//Sentry
import * as Sentry from '@sentry/react'
import packageInfo from '../package.json'
import { BrowserRouter } from 'react-router-dom'

window.handleData = data => {
	store.dispatch(updatePrintData(data.printData))
	store.dispatch(updateResultDetailStatus(data.status))
	store.dispatch(updatePrintStatus(data.printStatus))
	store.dispatch(updateResultDetailFilter('tagFilters', data.tagFilters))
	store.dispatch(
		updateResultDetailFilter('tagFiltersChoose', data.tagFiltersChoose)
	)
}

if (process.env.NODE_ENV !== 'development')
	Sentry.init({
		dsn:
			'https://d6dfb60179ca42138b10657649e86131@o1259454.ingest.sentry.io/6434191',
		integrations: [new Sentry.BrowserTracing()],
		environment: process.env.SENTRY_ENV || 'development',
		release: 'pokka@' + packageInfo.version || process.env.npm_package_version,
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.15,
		normalizeDepth: 10
	})

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
	<Provider store={store}>
		<BrowserRouter history={history}>
			<PersistGate loading={null} persistor={persistor}>
				<MuiThemeProvider theme={theme}>
					<App />
				</MuiThemeProvider>
			</PersistGate>
		</BrowserRouter>
	</Provider>
)
