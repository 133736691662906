/**
 * Theme initialisation
 * for components that use @materialui-core
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import { createTheme } from '@material-ui/core/styles'
import { isIE } from '../helpers/functionHelpers'

const defaultTypography = {
	// Use the system font instead of the default Roboto font.
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(','),
	fontSize: 16,
	htmlFontSize: 16,
	useNextVariants: true
}

const theme = createTheme({
	palette: {
		primary: { main: '#f81212' } // similar to $red color in scss
	},
	typography: {
		...defaultTypography
	},
	overrides: {
		// Select item font
		MuiMenuItem: {
			root: {
				fontFamily: 'Montserrat',
				fontSize: '1.4rem',
				fontWeight: 'bold',
				display: 'flex',
				flexDirection: 'row'
			}
		},
		// Select font
		MuiSelect: {
			root: {
				fontFamily: 'Montserrat',
				fontSize: '1.4rem',
				fontWeight: 'bold',
				borderWidth: 0,
				backgroundColor: '#EFEFEF',
				padding: '1rem 3.2rem 1rem 1rem',
				position: 'relative',
				width: '100%',
				overflow: 'hidden'
			}
		},
		MuiTouchRipple: {
			root: {
				display: isIE() ? 'none' : 'initial'
			}
		},
		//Checkbox
		MuiSvgIcon: {
			root: {
				width: '2.3rem',
				height: '2.3rem'
			}
		},
		MuiInputBase: {
			multiline: {
				padding: 0
			},
			root: {}
		}
	}
})

export { theme }
