import { CLEAR_REGISTER_FORM, UPDATE_REGISTER_FORM } from './types'

export const updateRegisterForm = (key, value) => ({
	type: UPDATE_REGISTER_FORM,
	payload: {
		key,
		value
	}
})

export const clearRegisterForm = () => ({
	type: CLEAR_REGISTER_FORM
})
