import { CLEAR_REGISTER_FORM, UPDATE_REGISTER_FORM } from '../actions/types'

const initialState = {
	firstName: '',
	lastName: '',
	organization: '',
	businessId: '',
	email: '',
	phone: ''
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_REGISTER_FORM:
			return {
				...state,
				[action.payload.key]: action.payload.value
			}
		case CLEAR_REGISTER_FORM:
			return { ...initialState }
		default:
			return state
	}
}

export default reducer

export const getFirstName = (state = initialState) => state.firstName
export const getLastname = (state = initialState) => state.lastName
export const getOrganization = (state = initialState) => state.organization
export const getBusinessId = (state = initialState) => state.businessId
export const getEmailRegister = (state = initialState) => state.email
export const getPhoneRegister = (state = initialState) => state.phone
