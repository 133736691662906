import {
	EDIT_SINGLE_RESULT_LOCAL,
	GET_USER_SURVEY_RESULTS,
	GET_USER_SURVEY_RESULTS_FAIL,
	GET_USER_SURVEY_RESULTS_SUCCESS,
	UPDATE_RESULT_FILTER,
	RESET_RESULTS_SUMMARY,
	CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS,
	RESULT_GET_SURVEY_BY_TITLE,
	RESULT_GET_SURVEY_BY_TITLE_SUCCESS,
	RESULT_GET_SURVEY_BY_TITLE_FAIL
} from '../actions/types'
import { sortByDate, sortByName } from '../helpers/sortHelper'
import { store } from '../config/store'
import { RESULT_STATUS } from '../constants'
import { editItemInArray } from '../helpers/functionHelpers'

const initialState = {
	results: [],
	filter1: 0,
	filter2: 0,
	filter3: 0,
	totalSurveyResults: 0
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_SURVEY_RESULTS:
			return { ...state }
		case GET_USER_SURVEY_RESULTS_FAIL:
			return { ...state }
		case GET_USER_SURVEY_RESULTS_SUCCESS:
			const { surveys, total } = action.payload.data
			return {
				...state,
				results: [...new Set([...state.results, ...surveys])],
				totalSurveyResults: total
			}
		case UPDATE_RESULT_FILTER:
			return {
				...state,
				[action.payload.filter]: action.payload.value,
				results:
					state[action.payload.filter] === action.payload.value
						? state.results
						: [],
				totalSurveyResults:
					state[action.payload.filter] === action.payload.value
						? state.totalSurveyResults
						: 0
			}
		case RESET_RESULTS_SUMMARY:
		case CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS: {
			return {
				...state,
				results: initialState.results,
				totalSurveyResults: initialState.totalSurveyResults
			}
		}
		case EDIT_SINGLE_RESULT_LOCAL:
			return {
				...state,
				results: editItemInArray(
					state.results,
					action.payload.newSurvey,
					action.payload.surveyIndex
				)
			}
		// Searching survey by title
		case RESULT_GET_SURVEY_BY_TITLE:
			return {
				...state,
				loading: true
			}
		case RESULT_GET_SURVEY_BY_TITLE_SUCCESS: {
			const { surveys, total } = action.payload.data
			return {
				...state,
				results: surveys,
				totalSurveyResults: total,
				loading: false
			}
		}
		case RESULT_GET_SURVEY_BY_TITLE_FAIL:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}

export default reducer

export const resultSelector = {
	getFilter1: (state = initialState) => state.filter1,
	getFilter2: (state = initialState) => state.filter2,
	getFilter3: (state = initialState) => state.filter3,
	getTotalSurveyResults: (state = initialState) => state.totalSurveyResults,
	getSurveyResults: (state = initialState) => state.results,
	getOriginalSurveyResults: (state = initialState) => state.results
}

const _filterResultByOwner = (result, filter) => {
	if (filter === 0) {
		return true
	} else if (filter === 1) {
		return result.user.id === store.getState().user.user.id
	}
}

const _sortResult = (result1, result2, sort) => {
	if (sort === 0) {
		return sortByDate(result1, result2, 'createdAt')
	} else if (sort === 1) {
		return sortByDate(result1, result2, 'updatedAt')
	} else if (sort === 2) {
		return sortByName(result1.title, result2.title)
	}
}
