import { compareDateAsc } from './dateHelper'

export const sortByName = (name1, name2) => {
	return name1.toLowerCase().localeCompare(name2.toLowerCase())
}

//Sort array by params
/**
 * @param  {} array
 * @param  {} params
 */
export const sortBy = (array, params) => {
	const cloneArray = [...array]
	return cloneArray.sort((item1, item2) => {
		if (item1[params] > item2[params]) {
			return 1
		} else if (item1[params] < item2[params]) {
			return -1
		} else {
			return 0
		}
	})
}

//Sort date time (ascending)
export const sortByDate = (survey1, survey2, key) => {
	const timeDiff = compareDateAsc(survey1[key], survey2[key])
	if (timeDiff > 0) return -1
	else if (timeDiff < 0) return 1
	else return 0
}
