import { apiWrapper } from '../helpers/apiHelpers'
import {
	CLEAR_NOTIFICATION_DRAFT,
	CREATE_NOTIFICATION,
	DELETE_NOTIFICATION,
	EDIT_NOTIFICATION,
	GET_ALL_NOTIFICATIONS,
	GET_UNREAD_NOTIFICATIONS,
	MARK_AS_SEEN_NOTIFICATION,
	UPDATE_NOTIFICATION_DRAFT
} from './types'

export const getAllNotifications = () => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_ALL_NOTIFICATIONS, {
			method: 'GET',
			url: `/notifications?all=true`
		})
	)
}

export const getUnreadNotifications = () => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_UNREAD_NOTIFICATIONS, {
			method: 'GET',
			url: `/notifications`
		})
	)
}
export const createNotification = noti => (dispatch, getState) => {
	return dispatch(
		apiWrapper(CREATE_NOTIFICATION, {
			method: 'POST',
			url: `/notifications`,
			data: noti
		})
	)
}

export const markAsSeenNotification = notiId => (dispatch, getState) => {
	return dispatch(
		apiWrapper(MARK_AS_SEEN_NOTIFICATION, {
			method: 'POST',
			url: `/notifications/${notiId}`
		})
	)
}

export const deleteNotification = notiId => (dispatch, getState) => {
	return dispatch(
		apiWrapper(DELETE_NOTIFICATION, {
			method: 'DELETE',
			url: `/notifications/${notiId}`
		})
	)
}

export const editNotification = (notiId, data) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(EDIT_NOTIFICATION, {
			method: 'PATCH',
			url: `/notifications/${notiId}`,
			data
		})
	)
}

export const updateNotificationDraft = (key, value) => ({
	type: UPDATE_NOTIFICATION_DRAFT,
	payload: {
		key,
		value
	}
})

export const clearNotificationDraft = () => ({
	type: CLEAR_NOTIFICATION_DRAFT
})
