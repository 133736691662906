/**
 * Created by Hai Anh on 7/22/19
 */
import { QUESTION_TYPE } from '../constants'

export const emailValidator = data => {
	const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regex.test(data)
}

export const passwordValidator = data => {
	return data && data.length >= 8
}

export const newPasswordValidator = data => {
	return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/.test(data)
}

export const emptyFieldValidator = data => {
	return data && data.length > 0
}

export const formValidator = (sections, page) => {
	const pageSection = sections.filter(section => section.page === page)
	//Check if all mandatory questions are filled
	return pageSection.every(section => {
		return section.questions.every(question => {
			if (!question.mandatory) return true
			else {
				const questionTypeId = question.type.id
				if (
					questionTypeId === QUESTION_TYPE.CHECKBOX_GRID ||
					questionTypeId === QUESTION_TYPE.RADIO_GRID
				)
					return gridValidator(question)
				else if (
					questionTypeId === QUESTION_TYPE.SHORT_ANSWER ||
					questionTypeId === QUESTION_TYPE.PARAGRAPH ||
					questionTypeId === QUESTION_TYPE.DATE
				) {
					return textAnswerValidator(question)
				} else if (questionTypeId === QUESTION_TYPE.NUMBER_INPUT) {
					return numberAnswerValidator(question)
				}
				return question.answer != null && question.answer.length > 0
			}
		})
	})
}

export const phoneValidator = data => {
	const regex = /^((([\+][\s]?)|([0]{2}[\s-]?))([358]{3})([\s-]?)|([0]))(([1-9][0-9]?)([\s-]?)([0-9]{2,4})([\s-]?)([0-9]{2,4})([\s-]?))([0-9]{0,3})$/
	return regex.test(data)
}

const gridValidator = question => {
	if (question.answer == null || question.answer.length === 0) return false
	// Check if answer includes every row in the question
	const allRows = []
	const answerRow = []
	question.answer.map(answer => {
		answerRow.push(answer.row)
	})
	for (let i = 1; i <= question.rows.length; i++) {
		allRows.push(i)
	}
	// Changed that on grid only some fields are required, not all of them
	return allRows.some(row => {
		return answerRow.includes(row)
	})
}

const textAnswerValidator = question => {
	if (isNull(question.answer)) return false
	//Check if answer includes every row in the question
	return question.answer.every(answer => {
		return !!answer.userInput && answer.userInput.toString().length > 0
	})
}

const numberAnswerValidator = question => {
	if (isNull(question.answer)) return false
	return question.answer.every(answer => {
		return (
			(!!answer.userInput || answer.userInput === 0) &&
			answer.userInput.toString().length > 0
		)
	})
}

const isNull = params => {
	return params == null || params.length === 0
}

export const lonelyMeterEducationValidator = choices => {
	return choices.includes('women_36') || choices.includes('men_36')
}

export const happyMeterEducationValidator = choices => {
	return choices.includes('women_27') || choices.includes('men_27')
}

export const isEmpty = value =>
	value === undefined ||
	value === null ||
	(typeof value === 'object' && Object.keys(value).length === 0) ||
	(Array.isArray(value) && value.length === 0) ||
	(typeof value === 'string' && value.trim().length === 0)
