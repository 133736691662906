/**
 * Login reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL } from '../actions/types'
import {
	CLEAR_LOGIN_FORM,
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_FAIL,
	FORGOT_PASSWORD_SUCCESS,
	UPDATE_LOGIN_FORM
} from '../actions/types'

const initialState = {
	email: '',
	password: '',
	loading: false
}

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				loading: true
			}
		case LOGIN_SUCCESS:
			return {
				...state,
				loading: false
			}
		case LOGIN_FAIL:
			return {
				...state,
				loading: false
			}
		case UPDATE_LOGIN_FORM:
			return {
				...state,
				[action.payload.key]: action.payload.value
			}
		case CLEAR_LOGIN_FORM:
			return { ...initialState }
		case FORGOT_PASSWORD:
			return { ...state, loading: true }
		case FORGOT_PASSWORD_FAIL:
			return { ...state, loading: false }
		case FORGOT_PASSWORD_SUCCESS:
			return { ...state, loading: false }
		default:
			return state
	}
}
export const loginSelector = {
	getEmail: (state = initialState) => state.email,
	getPassword: (state = initialState) => state.password,
	getLoading: (state = initialState) => state.loading
}

export default login
