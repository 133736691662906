/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import { PERSIST_KEY } from 'constants'
import i18n from '../i18n'

// error handling reducer
// return error message if there is error returned from action
const errorReducer = (state = null, action) => {
	const { type, error } = action
	// Because there might be different forms of error
	// returned from the server
	// Just return error and handle UI res (eg. message) inside component
	// If we came up with a "global" error return
	// then return error message here
	if (error && error.response && error.response.data) {
		// console.log(error.response.data)
		return error.response.data
	}
	if (error) {
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.error('Error log', error)
		}
		// Check for third-party errors
		if (/^@@/.test(action.type)) {
			if (typeof error === 'string') {
				return { message: error }
			} else {
				return { message: `Error: ${action.type}` }
			}
		}
		if (typeof error === 'string') {
			return { message: error }
		} else {
			return { message: i18n.t('error.something went wrong') }
		}
	}
	if (type === 'CLEAR_ERROR_MESSAGE') {
		return null
	}
	return state
}

// login reducer
import login, { loginSelector } from './login'

//register reducer
import register, {
	getEmailRegister,
	getFirstName,
	getLastname,
	getOrganization,
	getBusinessId,
	getPhoneRegister
} from './register'

//user reducer
import user, { getUser, getBearer, isAuthenticated, userSelector } from './user'

//appState reducer
import appState, { getGuideStep } from './appState'

//category reducer
import category, {
	getCategories,
	getCategoryLoading,
	categorySelector
} from './category'

//content reducer
import content, { contentSelector } from './content'

//survey reducer
import survey, {
	getDraftPublicLink,
	getDraftOpenDate,
	getDraftMailingList,
	getDraftCloseDate,
	getDraftSurveyTitle,
	getDraftAnonymous,
	getDraftSendByEmail,
	getDraftSenderEmail,
	getDraftEmailContent,
	getDraftEmailSubject,
	getOpennessPolicy,
	getDraftPublicLinkEnabled,
	getDraftSurveyThankYou,
	getDraftDateEnabled,
	surveySelector
} from './survey'

//export tag reducer
import tag, { getTagsSelector } from './tag'

//question reducer
import question, { questionTypesSelector } from './question'

//section reducer
import section, { getDraftSections } from './section'

//template reducer
import template, { templatesSelector } from './template'

//recipient reducer
import recipient, {
	recipientSelector,
	recipientDraftSelector
} from './recipient'
//success reducer
import success from './success'
import { RESET_REDUCER } from '../actions/types'
//organisation reducer
import organisation, {
	allOrganisationsSelector,
	organisationByIdSelector,
	organisationSelector
} from './organisation'
//result reducer
import result, { resultSelector } from './result'
//detail result reducer
import resultDetail, { resultDetailSelector } from './resultDetail'
//preview section reducer
import previewSection, { previewSectionSelector } from './previewSection'
//loading reducer
import loading, { loadingSelector } from './loading'
//file reducer
import file, { fileSelector } from './file'
//notification reducer
import notification, { notificationSelector } from './notification'
//image bank
import imageBank, { imageBankSelector } from './imageBank'
// analyzable templates
import analyzableTemplates, {
	analyzableTemplatesSelector
} from './analyzableTemplates'
import surveyTranslation from './surveyTranslation'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history/cjs/history'

const {
	createReduxHistory,
	routerMiddleware,
	routerReducer
} = createReduxHistoryContext({
	history: createBrowserHistory()
	//other options if needed
})

// export root reducer
const rootReducer = combineReducers({
	router: routerReducer,
	errorReducer,
	login,
	register,
	user,
	appState,
	category,
	content,
	survey,
	tag,
	question,
	section,
	template,
	recipient,
	success,
	organisation,
	result,
	resultDetail,
	previewSection,
	loading,
	file,
	notification,
	imageBank,
	analyzableTemplates,
	surveyTranslation
})

export default (state, action) => {
	if (action.type === RESET_REDUCER) {
		storage.removeItem(PERSIST_KEY)
		state = undefined
	}
	return rootReducer(state, action)
}

// export getters/ selectors
// these will be used in individual react component
export {
	// login selectors
	getUser,
	userSelector,
	getBearer,
	isAuthenticated,
	loginSelector,
	getEmailRegister,
	getFirstName,
	getLastname,
	getOrganization,
	getBusinessId,
	getCategories,
	getDraftSections,
	getTagsSelector,
	contentSelector,
	previewSectionSelector,
	surveySelector,
	resultSelector,
	resultDetailSelector,
	loadingSelector,
	categorySelector,
	templatesSelector,
	fileSelector,
	notificationSelector,
	imageBankSelector,
	analyzableTemplatesSelector,
	getGuideStep,
	getDraftAnonymous,
	getOpennessPolicy,
	getDraftSenderEmail,
	getDraftSendByEmail,
	getDraftSurveyTitle,
	getDraftCloseDate,
	getDraftMailingList,
	getDraftOpenDate,
	getDraftPublicLink,
	getDraftEmailContent,
	getDraftEmailSubject,
	getDraftPublicLinkEnabled,
	getDraftSurveyThankYou,
	getCategoryLoading,
	recipientSelector,
	recipientDraftSelector,
	getPhoneRegister,
	getDraftDateEnabled,
	allOrganisationsSelector,
	organisationSelector,
	createReduxHistory,
	routerMiddleware
}
