import { CHANGE_APP_STATE, UPDATE_GUIDE_STEP } from '../actions/types'
import { APP_STATE } from '../constants'

const initialState = {
	guideStep: 1,
	appState: APP_STATE.DEFAULT
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_GUIDE_STEP:
			return { ...state, guideStep: action.payload }
		case CHANGE_APP_STATE:
			return { ...state, appState: action.payload.appState }
		default:
			return state
	}
}

export default reducer

export const getGuideStep = (state = initialState) => state.guideStep

export const getAppState = (state = initialState) => state.appState
