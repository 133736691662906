import qs from 'qs'
import { apiWrapper } from '../helpers/apiHelpers'

import {
	CHANGE_SECTION_FILTER,
	CHANGE_SECTION_FILTER_ENABLED,
	CLEAR_RESULT_DETAIL_FILTER,
	CLEAR_SECTION_FILTER,
	CLEAR_ADVANCE_FILTER,
	CHANGE_ADVANCE_FILTER,
	GET_METER_COMPARISON,
	GET_RESULT_BY_SURVEY_ID,
	GET_RESULT_FILTERS_BY_SURVEY_ID,
	GET_RESULT_FILTERS_BY_TEMPLATE_ID,
	GET_SECTION_FILTER_RESULT,
	UPDATE_RESULT_DETAIL_FILTER,
	UPDATE_RESULT_DETAIL_STATUS,
	CLEAR_ADVANCE_COMPARISON,
	CLEAR_METER_COMPARISON_RESULT,
	GET_TAG_FILTER_COUNT,
	UPDATE_YEAR_FILTER,
	GET_SURVEY_ANSWERS,
	DELETE_SURVEY_ANSWER_BY_ID,
	GET_ORGANISATION_RESULT,
	GET_ORGANISATION_SECTION_FILTER,
	GET_ORGANISATION_SECTION_FILTER_COMPARISON,
	GET_ORGANISATION_ADVANCED_FILTER_COMPARISON
} from './types'

export const getResultBySurveyId = id => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_RESULT_BY_SURVEY_ID, {
			method: 'GET',
			url: `/results/${id}`,
			timeout: 30000
		})
	)
}

export const getResultFiltersBySurveyId = id => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_RESULT_FILTERS_BY_SURVEY_ID, {
			method: 'GET',
			url: `/results/${id}/filters`
		})
	)
}

export const getTemplateFiltersByTemplateId = (
	organisationId,
	templateId,
	query
) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_RESULT_FILTERS_BY_TEMPLATE_ID, {
			method: 'GET',
			url: `/results/organizations/${organisationId}/templates/${templateId}/filters?${query}`
		})
	)
}

export const updateResultDetailFilter = (filter, value) => ({
	type: UPDATE_RESULT_DETAIL_FILTER,
	payload: {
		filter,
		value
	}
})

export const clearResultDetailFilter = () => ({
	type: CLEAR_RESULT_DETAIL_FILTER
})

export const changeSectionFilterEnabled = value => ({
	type: CHANGE_SECTION_FILTER_ENABLED,
	payload: {
		value
	}
})

export const changeSectionFilter = (key, value) => ({
	type: CHANGE_SECTION_FILTER,
	payload: {
		key,
		value
	}
})

export const changeAdvanceFilter = (key, value) => ({
	type: CHANGE_ADVANCE_FILTER,
	payload: {
		key,
		value
	}
})

export const getSectionFilterResult = (surveyId, query) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(GET_SECTION_FILTER_RESULT, {
			method: 'GET',
			url: `/results/${surveyId}/filtered?${query}`
		})
	)
}

export const clearSectionFilter = () => ({
	type: CLEAR_SECTION_FILTER
})

export const clearAdvanceFilter = () => ({
	type: CLEAR_ADVANCE_FILTER
})
export const clearAdvanceComparison = () => ({
	type: CLEAR_ADVANCE_COMPARISON
})
export const updateResultDetailStatus = status => ({
	type: UPDATE_RESULT_DETAIL_STATUS,
	payload: {
		status
	}
})

// Meter comparison
export const getMeterComparison = (meterId, params) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_METER_COMPARISON, {
			method: 'GET',
			url: `/results/comparison/meter/${meterId}?${params}`
		})
	)
}

export const clearMeterComparisonResult = () => ({
	type: CLEAR_METER_COMPARISON_RESULT
})

// Tag filter count
export const getTagFilterCount = (surveyId, params) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GET_TAG_FILTER_COUNT, {
			url: `/results/${surveyId}/count?${params}`
		})
	)
}

// Update year filter
export const updateYearFilter = (key, value) => ({
	type: UPDATE_YEAR_FILTER,
	payload: {
		key,
		value
	}
})

// Get all answers to survey question and delete
export const getSurveyAnswers = (
	organizationId,
	surveyId,
	searchText = ''
) => dispatch => {
	return dispatch(
		apiWrapper(GET_SURVEY_ANSWERS, {
			method: 'GET',
			url: `/answers/text/organization/${organizationId}/?search=${searchText}&survey=${surveyId}`
		})
	)
}

export const deleteAnswersById = answerId => dispatch => {
	return dispatch(
		apiWrapper(DELETE_SURVEY_ANSWER_BY_ID, {
			method: 'DELETE',
			url: `/answers/${answerId}`
		})
	)
}

export const getOrganisationResult = (
	orgId,
	templateId,
	queryParams
) => dispatch => {
	return dispatch(
		apiWrapper(GET_ORGANISATION_RESULT, {
			method: 'GET',
			url: `/results/organizations/${orgId}/templates/${templateId}?${queryParams}`
			// url: `/results/organizations/${orgId}/templates/${templateId}/comparison/filtered?${queryParams}` old endpoint
		})
	)
}

export const getOrganisationSectionFilter = (orgId, templateId, query) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(GET_ORGANISATION_SECTION_FILTER, {
			method: 'GET',
			url: `/results/organizations/${orgId}/templates/${templateId}/filtered?${query}`
		})
	)
}
export const getOrganisationComparisonFilter = (orgId, templateId, query) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(GET_ORGANISATION_SECTION_FILTER_COMPARISON, {
			method: 'GET',
			url: `/results/organizations/${orgId}/templates/${templateId}/comparison/filtered?${query}`
		})
	)
}
export const getOrganisationAdvancedFilter = (orgId, templateId, query) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(GET_ORGANISATION_ADVANCED_FILTER_COMPARISON, {
			method: 'GET',
			url: `/results/organizations/${orgId}/templates/${templateId}/comparison/filtered?${query}`
		})
	)
}
