import { maxBy, minBy, sort } from './functionHelpers'
import { QUESTION_TYPE } from '../constants'
import { sortBy } from './sortHelper'

const showTargetValue = question => {
	if (question.type.id === QUESTION_TYPE.RADIO_GRID) {
		return question.rows.some(row => row.targetValue != null) || false
	}
	return false
}

export const _sectionTransformer = sections => {
	return sections.slice().map((section, i) => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		newSection.title = newSection.title.trim()
		//Ordinal will start at 1
		newSection.ordinal = i + 1
		delete newSection.id
		newSection.questions = section.questions.slice().map(question => {
			const newQuestion = { ...question }
			const isTargetValue = showTargetValue(newQuestion)
			newQuestion.text = newQuestion.text.trim()
			delete newQuestion.id
			if (
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE_HORIZONTAL ||
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE
			) {
				newQuestion.useEmoji = checkIfUseEmoji([...newQuestion.columns])
			}
			newQuestion.rows = question.rows.slice().map((row, i) => {
				const newRow = { ...row }
				newRow.textualValue = newRow.textualValue.trim()
				delete newRow.id
				newRow.ordinal = i + 1
				if (newQuestion.type.id === QUESTION_TYPE.RADIO_GRID) {
					if (isTargetValue && newRow.targetValue != null)
						newRow.targetValue = Number.parseFloat(
							newRow.targetValue.replace(',', '.')
						)
					else newRow.targetValue = null
				}
				return newRow
			})
			newQuestion.columns = question.columns.slice().map((column, i) => {
				const newColumn = { ...column }
				newColumn.textualValue = newColumn.textualValue.trim()
				delete newColumn.id
				newColumn.ordinal = i + 1
				if (
					newColumn.numericalValue !== 0 &&
					(newQuestion.type.id === QUESTION_TYPE.RADIO_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_GRID ||
						newQuestion.type.id === QUESTION_TYPE.RADIO_GRID)
				) {
					newColumn.numericalValue = i + 1
				}
				return newColumn
			})
			return newQuestion
		})
		return newSection
	})
}

export const _publishSectionTransformer = sections => {
	return sections.slice().map((section, i) => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		newSection.title = newSection.title.trim()
		//Ordinal will start at 1
		newSection.ordinal = i + 1
		delete newSection.id
		newSection.questions = section.questions.slice().map(question => {
			const newQuestion = { ...question }
			const isTargetValue = showTargetValue(newQuestion)
			newQuestion.text = newQuestion.text.trim()
			delete newQuestion.id
			if (newQuestion.questionTranslation) {
				delete newQuestion.questionTranslation
			}
			if (
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE_HORIZONTAL ||
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE
			) {
				newQuestion.useEmoji = checkIfUseEmoji([...newQuestion.columns])
			}
			newQuestion.rows = question.rows.slice().map((row, i) => {
				const newRow = { ...row }
				newRow.textualValue = newRow.textualValue.trim()
				//Set comparable to true
				newRow.isComparable = true
				delete newRow.id
				if (newRow.questionRowTranslation) {
					delete newRow.questionRowTranslation
				}
				newRow.ordinal = i + 1
				if (newQuestion.type.id === QUESTION_TYPE.RADIO_GRID) {
					if (isTargetValue && newRow.targetValue != null)
						newRow.targetValue = Number.parseFloat(
							newRow.targetValue.replace(',', '.')
						)
					else newRow.targetValue = null
				}
				return newRow
			})
			newQuestion.columns = question.columns.slice().map((column, i) => {
				const newColumn = { ...column }
				newColumn.textualValue = newColumn.textualValue.trim()
				delete newColumn.id
				if (newColumn.questionColumnTranslation) {
					delete newColumn.questionColumnTranslation
				}
				newColumn.ordinal = i + 1
				if (
					newColumn.numericalValue !== 0 &&
					(newQuestion.type.id === QUESTION_TYPE.RADIO_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_GRID ||
						newQuestion.type.id === QUESTION_TYPE.RADIO_GRID)
				) {
					newColumn.numericalValue = i + 1
				}
				return newColumn
			})
			return newQuestion
		})
		return newSection
	})
}

export const _createSectionTransformer = sections => {
	return sortBy(sections, 'ordinal').map((section, i) => {
		const newSection = { ...section }
		newSection.title = newSection.title.trim()
		delete newSection.id
		newSection.questions = sortBy(section.questions, 'order')
			.slice()
			.map(question => {
				const newQuestion = { ...question }
				const isTargetValue = showTargetValue(newQuestion)
				newQuestion.text = newQuestion.text.trim()
				delete newQuestion.id
				if (
					newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE_HORIZONTAL ||
					newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE
				) {
					newQuestion.useEmoji = checkIfUseEmoji([...newQuestion.columns])
				}
				newQuestion.rows = sortBy(question.rows, 'ordinal').map((row, i) => {
					const newRow = { ...row }
					newRow.textualValue = newRow.textualValue.trim()
					delete newRow.id
					if (newQuestion.type.id === QUESTION_TYPE.RADIO_GRID) {
						if (isTargetValue && newRow.targetValue != null)
							newRow.targetValue = Number.parseFloat(
								newRow.targetValue.replace(',', '.')
							)
						else newRow.targetValue = null
					}
					return newRow
				})
				newQuestion.columns = sortBy(question.columns, 'ordinal').map(
					(column, i) => {
						const newColumn = { ...column }
						newColumn.textualValue = newColumn.textualValue.trim()
						delete newColumn.id
						if (
							newColumn.numericalValue !== 0 &&
							(newQuestion.type.id === QUESTION_TYPE.RADIO_HORIZONTAL ||
								newQuestion.type.id === QUESTION_TYPE.CHECKBOX_HORIZONTAL ||
								newQuestion.type.id === QUESTION_TYPE.CHECKBOX_GRID ||
								newQuestion.type.id === QUESTION_TYPE.RADIO_GRID)
						) {
							newColumn.numericalValue = i + 1
						}
						return newColumn
					}
				)
				return newQuestion
			})
		return newSection
	})
}

export const _sectionTemplateTransformer = (sections, isEdited = false) => {
	return sections.slice().map((section, i) => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		newSection.title = newSection.title.trim()
		if (!isEdited) {
			delete newSection.tooltip
		}
		//Ordinal will start at 1
		newSection.ordinal = i + 1
		newSection.template = true
		newSection.questions = section.questions.slice().map(question => {
			const newQuestion = { ...question }

			const isTargetValue = showTargetValue(newQuestion)
			newQuestion.text = newQuestion.text.trim()
			if (
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE_HORIZONTAL ||
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE
			) {
				newQuestion.useEmoji = checkIfUseEmoji([...newQuestion.columns])
			}
			newQuestion.rows = question.rows.slice().map((row, i) => {
				const newRow = { ...row }
				newRow.textualValue = newRow.textualValue.trim()
				newRow.ordinal = i + 1
				if (newQuestion.type.id === QUESTION_TYPE.RADIO_GRID) {
					if (isTargetValue && newRow.targetValue != null)
						newRow.targetValue = Number.parseFloat(
							newRow.targetValue.replace(',', '.')
						)
					else newRow.targetValue = null
				}
				return newRow
			})
			newQuestion.columns = question.columns.slice().map((column, i) => {
				const newColumn = { ...column }
				newColumn.textualValue = newColumn.textualValue.trim()
				newColumn.oridnal = i + 1
				return newColumn
			})
			newQuestion.editable = true
			return newQuestion
		})
		return newSection
	})
}

export const _receivedSectionTransformer = sections => {
	const newSections = sort([...sections], 'ordinal')
	return newSections.map((section, i) => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		newSection.questions = sort(newSection.questions, 'order')
		newSection.questions = newSection.questions.map(question => {
			const newQuestion = { ...question }
			newQuestion.isTargetValue = showTargetValue(newQuestion)
			if (
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE_HORIZONTAL ||
				newQuestion.type.id === QUESTION_TYPE.LINEAR_SCALE
			) {
				newQuestion.useEmoji = checkIfUseEmoji([...question.columns])
			}
			newQuestion.rows = sort(newQuestion.rows, 'ordinal')
			newQuestion.rows = newQuestion.rows.map((row, i) => {
				const newRow = { ...row }
				if (newRow.targetValue) {
					newRow.targetValue = `${Number.parseFloat(newRow.targetValue).toFixed(
						1
					)}`.replace('.', ',')
				}
				return newRow
			})
			newQuestion.columns = sort(newQuestion.columns, 'ordinal')
			return newQuestion
		})
		return newSection
	})
}

export const _reorderAnswerSections = sections => {
	const newSections = sort([...sections], 'ordinal')
	return newSections.map((section, i) => {
		const newSection = { ...section }
		newSection.questions = sort(newSection.questions, 'order')
		newSection.questions = newSection.questions.map(question => {
			const newQuestion = { ...question }
			newQuestion.rows = sort(newQuestion.rows, 'ordinal')
			newQuestion.columns = sort(newQuestion.columns, 'ordinal')
			return newQuestion
		})
		return newSection
	})
}

export const _contentSaveTransformer = sections => {
	const newSections = sort([...sections], 'ordinal')
	return newSections.map((section, i) => {
		const newSection = { ...section }
		newSection.questions = sort(newSection.questions, 'order')
		newSection.questions = newSection.questions.map(question => {
			const newQuestion = { ...question }
			newQuestion.isTargetValue = showTargetValue(newQuestion)
			newQuestion.rows = sort(newQuestion.rows, 'ordinal')
			newQuestion.columns = sort(newQuestion.columns, 'ordinal')
			return newQuestion
		})
		return newSection
	})
}

export const _sectionTemplateSaveTransformer = section => {
	if (Array.isArray(section.categories)) {
		section.categories = section.categories.map(({ id }) => id)
	}
	section.questions = sort(section.questions, 'order')
	section.questions = section.questions.map(question => {
		const newQuestion = { ...question }
		newQuestion.isTargetValue = showTargetValue(newQuestion)
		newQuestion.rows = sort(newQuestion.rows, 'ordinal')
		newQuestion.columns = sort(newQuestion.columns, 'ordinal')
		return newQuestion
	})
	return [section]
}

export const _editedSectionTransformer = sections => {
	return sections.slice().map((section, i) => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		newSection.title = newSection.title.trim()
		//Ordinal will start at 1
		newSection.ordinal = i + 1
		newSection.questions = section.questions.slice().map(question => {
			const newQuestion = { ...question }

			const isTargetValue = showTargetValue(newQuestion)
			newQuestion.text = newQuestion.text.trim()
			newQuestion.rows = question.rows.slice().map((row, i) => {
				const newRow = { ...row }
				newRow.textualValue = newRow.textualValue.trim()
				newRow.ordinal = i + 1
				if (newQuestion.type.id === QUESTION_TYPE.RADIO_GRID) {
					if (isTargetValue && newRow.targetValue != null)
						newRow.targetValue = Number.parseFloat(
							newRow.targetValue.replace(',', '.')
						)
					else newRow.targetValue = null
				}
				return newRow
			})
			newQuestion.columns = question.columns.slice().map((column, i) => {
				const newColumn = { ...column }
				newColumn.textualValue = newColumn.textualValue.trim()
				newColumn.ordinal = i + 1
				if (
					newColumn.numericalValue !== 0 &&
					(newQuestion.type.id === QUESTION_TYPE.RADIO_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_GRID ||
						newQuestion.type.id === QUESTION_TYPE.RADIO_GRID)
				) {
					newColumn.numericalValue = i + 1
				}
				return newColumn
			})
			return newQuestion
		})
		return newSection
	})
}

export const _editedPublishSectionTransformer = sections => {
	return sections.slice().map((section, i) => {
		// Since using immerjs, the reducer is read only => Spread operator to get the editable copy
		const newSection = { ...section }
		newSection.title = newSection.title.trim()
		//Ordinal will start at 1
		newSection.ordinal = i + 1
		newSection.questions = section.questions.slice().map(question => {
			const newQuestion = { ...question }
			if (newQuestion.questionTranslation) {
				delete newQuestion.questionTranslation
			}
			const isTargetValue = showTargetValue(newQuestion)
			newQuestion.text = newQuestion.text.trim()
			newQuestion.rows = question.rows.slice().map((row, i) => {
				const newRow = { ...row }
				newRow.textualValue = newRow.textualValue.trim()
				newRow.isComparable = true
				newRow.ordinal = i + 1
				if (newRow.questionRowTranslation) {
					delete newRow.questionRowTranslation
				}
				if (newQuestion.type.id === QUESTION_TYPE.RADIO_GRID) {
					if (isTargetValue && newRow.targetValue != null)
						newRow.targetValue = Number.parseFloat(
							newRow.targetValue.replace(',', '.')
						)
					else newRow.targetValue = null
				}
				return newRow
			})
			newQuestion.columns = question.columns.slice().map((column, i) => {
				const newColumn = { ...column }
				newColumn.textualValue = newColumn.textualValue.trim()
				newColumn.ordinal = i + 1
				if (newColumn.questionColumnTranslation) {
					delete newColumn.questionColumnTranslation
				}
				if (
					newColumn.numericalValue !== 0 &&
					(newQuestion.type.id === QUESTION_TYPE.RADIO_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_HORIZONTAL ||
						newQuestion.type.id === QUESTION_TYPE.CHECKBOX_GRID ||
						newQuestion.type.id === QUESTION_TYPE.RADIO_GRID)
				) {
					newColumn.numericalValue = i + 1
				}
				return newColumn
			})
			return newQuestion
		})
		return newSection
	})
}

const _templateTransformer = template => {
	if (Array.isArray(template.categories)) {
		template.categories = template.categories.map(category => category.id)
	}
	template.questions = sort(template.questions, 'order')
	template.questions = template.questions.map(question => {
		const newQuestion = { ...question }
		newQuestion.isTargetValue = showTargetValue(newQuestion)
		newQuestion.rows = sort(newQuestion.rows, 'ordinal')
		newQuestion.rows = newQuestion.rows.map((row, i) => {
			const newRow = { ...row }
			if (newRow.targetValue) {
				newRow.targetValue = `${Number.parseFloat(newRow.targetValue).toFixed(
					1
				)}`.replace('.', ',')
			}
			return newRow
		})
		newQuestion.columns = sort(newQuestion.columns, 'ordinal')
		return newQuestion
	})
	return template
}

export const templatesTransformer = templates => {
	return templates.map(_templateTransformer)
}

export const templateToSectionTransformer = addingTemplate => {
	const template = { ...addingTemplate }
	template.template = true
	template.deletable = true
	template.imageQuestion = false
	template.editable = false
	template.meter = {
		id: template.id
	}
	template.organization = template.organization
		? template.organization.id
		: null
	delete template.id
	template.questions = template.questions.map(question => {
		const newQuestion = { ...question }
		delete newQuestion.id
		newQuestion.isTargetValue = showTargetValue(newQuestion)
		newQuestion.rows = sort(newQuestion.rows, 'ordinal')
		newQuestion.rows = newQuestion.rows.map((row, i) => {
			const newRow = { ...row }
			delete newRow.id
			if (newRow.targetValue) {
				newRow.targetValue = `${Number.parseFloat(newRow.targetValue).toFixed(
					1
				)}`.replace('.', ',')
			}
			return newRow
		})
		newQuestion.columns = sort(newQuestion.columns, 'ordinal').map(column => {
			delete column.id
			return column
		})
		return newQuestion
	})
	return template
}

export const copySectionsTransformer = sections => {
	return sections.map(copySectionTransformer)
}

export const copySectionTransformer = section => {
	const newSection = { ...section }
	newSection.editable = true
	newSection.deletable = true
	delete newSection.id
	newSection.questions = section.questions.slice().map(question => {
		const newQuestion = { ...question }
		newQuestion.isTargetValue = showTargetValue(newQuestion)
		delete newQuestion.id
		newQuestion.rows = question.rows.slice().map((row, i) => {
			const newRow = { ...row }
			newRow.isComparable = true
			delete newRow.id
			return newRow
		})
		newQuestion.columns = question.columns.slice().map((column, i) => {
			const newColumn = { ...column }
			delete newColumn.id
			return newColumn
		})
		return newQuestion
	})
	return newSection
}

export const copySectionWithoutIdentifierTransformer = section => {
	const newSection = { ...section }
	newSection.editable = true
	newSection.deletable = true
	delete newSection.id
	newSection.questions = section.questions.slice().map(question => {
		const newQuestion = { ...question }
		newQuestion.isTargetValue = showTargetValue(newQuestion)
		delete newQuestion.id
		newQuestion.rows = question.rows.slice().map((row, i) => {
			const newRow = { ...row }
			newRow.isComparable = false
			delete newRow.identifier
			delete newRow.id
			return newRow
		})
		newQuestion.columns = question.columns.slice().map((column, i) => {
			const newColumn = { ...column }
			delete newColumn.id
			return newColumn
		})
		return newQuestion
	})
	return newSection
}
const checkIfUseEmoji = options => {
	if (!options || options.length === 0) return false
	const maxIndex = maxBy(options, 'numericalValue')
	const minIndex = minBy(options, 'numericalValue')
	const regex = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
	return (
		regex.test(options[minIndex].textualValue) ||
		regex.test(options[maxIndex].textualValue)
	)
}

export const resultTransformer = result => {
	const cloneContent = { ...result.content }
	cloneContent.sections = _reorderAnswerSections([...cloneContent.sections])
	result.content = cloneContent
	return result
}
export const combineResultTransformer = result => {
	const cloneContent = { ...result.content }
	cloneContent.sections = _reorderAnswerSections([...cloneContent.sections])
	result.content = cloneContent
	return result
}

export const organisationResultTransformer = result => {
	const cloneContent = { ...result }
	cloneContent.sections = _reorderAnswerSections([...cloneContent.sections])
	result.content = cloneContent
	return result
}
