import {
	GET_QUESTION_TYPES,
	GET_QUESTION_TYPES_FAIL,
	GET_QUESTION_TYPES_SUCCESS
} from '../actions/types'

const initialState = {
	questionTypes: [],
	loading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_QUESTION_TYPES:
			return {
				...state,
				loading: true
			}
		case GET_QUESTION_TYPES_SUCCESS:
			return {
				...state,
				questionTypes: action.payload.data,
				loading: false
			}
		case GET_QUESTION_TYPES_FAIL:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}

export default reducer

export const questionTypesSelector = (state = initialState) =>
	state.questionTypes
