import {
	CLEAR_CONTENT_DRAFT,
	UPDATE_CONTENT_ANSWER,
	UPDATE_CONTENT_DRAFT,
	UPDATE_CONTENT_LANGUAGES_SUCCESS,
	UPDATE_WHOLE_CONTENT_DRAFT
} from '../actions/types'

const initialState = {
	draft: {
		title: '',
		description: '',
		instructions: '',
		tags: [],
		sections: [],
		firstPageBreak: true,
		availableSurveyLanguages: null,
		defaultLanguage: null,
		id: -1
	},
	answer: {
		title: '',
		description: '',
		firstPageBreak: true
	},
	loading: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_CONTENT_DRAFT:
			return {
				...state,
				draft: { ...state.draft, [action.payload.key]: action.payload.value }
			}
		case UPDATE_WHOLE_CONTENT_DRAFT:
			return { ...state, draft: { ...action.payload.content } }
		case CLEAR_CONTENT_DRAFT:
			return { ...state, draft: { ...initialState.draft } }
		case UPDATE_CONTENT_ANSWER:
			return {
				...state,
				answer: { ...state.answer, [action.payload.key]: action.payload.value }
			}
		case UPDATE_CONTENT_LANGUAGES_SUCCESS:
			return {
				...state,
				draft: { ...state.draft, availableSurveyLanguages: action.payload.data }
			}
		default:
			return state
	}
}

export default reducer

export const contentSelector = {
	getDraftFirstPageBreak: (state = initialState) => state.draft.firstPageBreak,
	getDraftContentTitle: (state = initialState) => state.draft.title,
	getDraftDescription: (state = initialState) => state.draft.description,
	getDraftTags: (state = initialState) => state.draft.tags,
	getDraftInstructions: (state = initialState) => state.draft.instructions,
	getDraftContent: (state = initialState) => ({
		id: state.draft.id,
		title: state.draft.title,
		description: state.draft.description,
		instructions: state.draft.instructions,
		tags: state.draft.tags,
		firstPageBreak: state.draft.firstPageBreak,
		availableSurveyLanguages: state.draft.availableSurveyLanguages,
		defaultLanguage: state.draft.defaultLanguage
	}),
	getDraftContentForTranslations: (state = initialState) => ({
		id: state.draft.id,
		title: state.draft.title,
		description: state.draft.description,
		instructions: state.draft.instructions,
		tags: state.draft.tags,
		availableSurveyLanguages: state.draft.availableSurveyLanguages,
		defaultLanguage: state.draft.defaultLanguage,
		firstPageBreak: state.draft.firstPageBreak
	}),
	getDraftContentId: (state = initialState) => state.draft.id,
	getAnswerContentTitle: (state = initialState) => state.answer.title,
	getAnswerContentDescription: (state = initialState) =>
		state.answer.description,
	getAnswerFirstPageBreak: (state = initialState) =>
		state.answer.firstPageBreak,
	getDraftContentComparable: (state = initialState) => ({
		title: state.draft.title,
		description: state.draft.description,
		instructions: state.draft.instructions,
		tags: state.draft.tags,
		firstPageBreak: state.draft.firstPageBreak
	}),
	getDraftContentDefaultLanguage: (state = initialState) =>
		state.draft.defaultLanguage
}
