import qs from 'qs'
import i18n from '../i18n'
import { getFirstDayOfYear, getLastDayOfYear } from './dateHelper'

export const apiWrapper = (actionType, requestConfig, isSecure = true) => (
	dispatch,
	getState
) => {
	let state = getState()
	let action = {
		type: actionType,
		payload: {
			request: {
				...requestConfig,
				url: requestConfig.url,
				headers: {
					...requestConfig.headers,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache'
				}
			}
		}
	}
	if (isSecure) {
		let request = action.payload.request
		request.headers = {
			...request.headers,
			bearer: state.user.bearer
		}
	}
	return dispatch(action)
}

export const sectionFilterQueryBuilder = identifierList => {
	if (!Array.isArray(identifierList) || !identifierList.length) {
		return null
	}
	const queryObject = {
		filters: {
			0: {
				value: 1,
				identifier: ''
			}
		}
	}
	identifierList.map((identifier, i) => {
		if (typeof identifier === 'object' && identifier.type === 'age') {
			const currentYear = new Date().getFullYear()
			//Switch the age to year
			const queryValue = [
				currentYear - identifier.value[1],
				currentYear - identifier.value[0]
			]
			queryObject.filters[i] = {
				value: queryValue,
				name: `${i18n.t('resultDetail.age group')} ${identifier.value[0]}-${
					identifier.value[1]
				}`,
				identifier: identifier.identifier,
				type: identifier.type
			}
		} else if (
			typeof identifier === 'object' &&
			identifier.type === 'answerTime'
		) {
			const { answerTimeStart, answerTimeEnd } = identifier
			// Put values for start and end time
			const queryValue = [answerTimeStart, answerTimeEnd]
			queryObject.filters[i] = {
				value: queryValue,
				name: i18n.t('resultDetail.answer time filter'),
				type: identifier.type
			}
		} else if (
			typeof identifier === 'object' &&
			identifier.type === 'answererNumber'
		) {
			const { type, value } = identifier
			// Put values for start and end time
			queryObject.filters[i] = {
				value,
				name: i18n.t('resultDetail.answerer number'),
				type
			}
		}
		// This comes from sectionfilter index. If type is text, then it will build a different queery for searching texts
		else if (
			typeof identifier === 'object' &&
			(identifier.type === 'text' || identifier.type === 'number')
		) {
			queryObject.filters[i] = {
				value: identifier.value,
				identifier: identifier.identifier,
				type: identifier.type
			}
		} else {
			queryObject.filters[i] = {
				value: 1,
				identifier: identifier
			}
		}
	})
	return qs.stringify(queryObject, { encode: false })
}

export const sectionComparisonQueryBuilder = (identifierList, nameList) => {
	if (!Array.isArray(identifierList)) {
		return null
	}
	const queryObject = {
		filters: {
			0: {
				value: 1,
				identifier: '',
				name: '',
				type: 'in'
			}
		}
	}
	identifierList.map((identifier, i) => {
		// Age Filter
		if (typeof identifier === 'object' && identifier.type === 'age') {
			const currentYear = new Date().getFullYear()
			//Switch the age to year
			const queryValue = [
				currentYear - identifier.value[1],
				currentYear - identifier.value[0]
			]
			queryObject.filters[i] = {
				value: queryValue,
				name: `${i18n.t('resultDetail.answer time filter')} ${
					identifier.value[0]
				}-${identifier.value[1]}`,
				identifier: identifier.identifier,
				type: identifier.type
			}
		} else if (
			typeof identifier === 'object' &&
			identifier.type === 'answerTime'
		) {
			const { answerTimeStart, answerTimeEnd } = identifier
			// Put values for start and end time
			const queryValue = [answerTimeStart, answerTimeEnd]
			queryObject.filters[i] = {
				value: queryValue,
				name: i18n.t('resultDetail.answer time filter'),
				type: identifier.type
			}
		} else if (identifier.type === 'answererNumber') {
			const { type, value } = identifier
			// Put values for start and end time
			queryObject.filters[i] = {
				value,
				name: i18n.t('resultDetail.answerer number'),
				type
			}
		}
		// This is for search text with comparison in result detail section filter page
		else if (
			typeof identifier === 'object' &&
			(identifier.type === 'text' || identifier.type === 'number')
		) {
			queryObject.filters[i] = {
				value: identifier.value,
				identifier: identifier.identifier,
				type: identifier.type,
				name: identifier.value
			}
		} else {
			queryObject.filters[i] = {
				value: 1,
				name: filterNameGenerator(nameList[i]),
				identifier,
				type: 'in'
			}
		}
	})
	queryObject.compareToOwn = true

	return qs.stringify(queryObject, { encode: false })
}

export const organisationComparisonQueryBuilder = valueArray => {
	let queryString = `filters[9][type]=organization&filters[9][compareTo]=`
	valueArray.forEach((val, i) => {
		if (i === 0) {
			queryString += `${val}`
		} else {
			queryString += `&filters[9][compareTo]=${val}`
		}
	})
	return queryString
}
export const organisationComparisonTestQueryBuilder = valueArray => {
	let queryString = `filters[9][type]=organization&filters[9][compareTo]=`
	valueArray.forEach((val, i) => {
		if (i === 0) {
			queryString += `${val}`
		} else {
			queryString += `&filters[9][compareTo]=${val}`
		}
	})
	return queryString
}
export const selectedOrganisationQueryBuilder = valueArray => {
	let queryString = `filters[8][name]=organization&filters[8][value]=`
	valueArray.forEach((val, i) => {
		if (i === 0) {
			queryString += `${val}`
		} else {
			queryString += `&filters[8][value]=${val}`
		}
	})
	return queryString
}
export const selectedOrganisationSurveyQueryBuilder = valueArray => {
	let queryString = `filters[9][name]=survey&filters[9][value]=`
	valueArray.forEach((val, i) => {
		if (i === 0) {
			queryString += `${val}`
		} else {
			queryString += `&filters[9][value]=${val}`
		}
	})
	return queryString
}
export const selectedOrganisationComparisonQueryBuilder = valueArray => {
	let queryString = `&filters[8][name]=organization&filters[8][value]=`
	valueArray.forEach((val, i) => {
		if (i === 0) {
			queryString += `${val}`
		} else {
			queryString += `&filters[8][value]=${val}`
		}
	})
	return queryString
}

export const surveyFilterQueryBuilder = surveyList => {
	const queryObject = {
		filters: {
			0: {
				value: 1,
				name: '',
				type: 'survey'
			}
		}
	}
	surveyList.map((survey, i) => {
		queryObject.filters[i] = {
			value: survey.id,
			name: filterNameGenerator(survey.title),
			type: 'survey'
		}
	})
	return qs.stringify(queryObject, { encode: false })
}

export const advanceFilterQueryBuilder = (surveyFilterList, filterNameList) => {
	const queryObject = {
		filters: {
			0: {
				value: 1,
				name: '',
				type: 'survey'
			}
		}
	}
	surveyFilterList.forEach(({ filterId, identifiers }, i) => {
		if (identifiers.length > 0 && identifiers[0].type === 'age') {
			const currentYear = new Date().getFullYear()
			//Switch the age to year
			const queryValue = [
				currentYear - identifiers[0].value[1],
				currentYear - identifiers[0].value[0]
			]
			queryObject.filters[i] = {
				identifier: identifiers[0].identifier,
				name: filterNameList[filterId],
				type: 'age',
				and: true,
				value: queryValue
			}
		} else if (identifiers.length > 0 && identifiers[0].type === 'answerTime') {
			const { answerTimeStart, answerTimeEnd, type } = identifiers[0]
			// Put values for start and end time
			const queryValue = [answerTimeStart, answerTimeEnd]
			queryObject.filters[i] = {
				value: queryValue,
				name: filterNameList[filterId],
				type: type
			}
		} else if (
			identifiers.length > 0 &&
			identifiers[0].type === 'answererNumber'
		) {
			const { value, type } = identifiers[0]
			// Put values for start and end time
			queryObject.filters[i] = {
				value,
				name: filterNameList[filterId],
				type
			}
		} else if (
			typeof identifiers[0] === 'object' &&
			(identifiers[0].type === 'text' || identifiers[0].type === 'number')
		) {
			queryObject.filters[i] = {
				value: identifiers[0].value,
				identifier: identifiers[0].identifier,
				type: identifiers[0].type,
				name: identifiers[0].value
			}
		} else {
			queryObject.filters[i] = {
				value: 1,
				name: filterNameList[filterId],
				type: 'and',
				identifier: identifiers
			}
		}
	})
	queryObject.compareToOwn = true
	return qs.stringify(queryObject, { encode: false })
}

export const advanceComparisonQueryBuilder = (
	comparisonList,
	comparisonFilter,
	surveyId,
	ownFilterName
) => {
	const queryObject = {
		filters: {}
	}

	const queryArray = []
	comparisonList.forEach(
		({
			name,
			identifiers,
			type,
			value,
			filterYearLeft,
			filterYearRight,
			answerTime,
			answererNumber
		}) => {
			if (type !== '') {
				const startDate = getFirstDayOfYear(filterYearLeft)
				const endDate = getLastDayOfYear(filterYearRight)
				queryArray.push({
					value,
					name,
					type,
					startDate,
					endDate
				})
			}
			if (identifiers.length > 0 && identifiers[0].type === 'age') {
				const currentYear = new Date().getFullYear()
				//Switch the age to year
				const queryValue = [
					currentYear - identifiers[0].value[1],
					currentYear - identifiers[0].value[0]
				]
				queryArray.push({
					identifier: identifiers[0].identifier,
					name,
					type: 'age',
					and: true,
					value: queryValue
				})
			} else if (answerTime && answerTime.type === 'answerTime') {
				const {
					answerTimeStart = null,
					answerTimeEnd = null,
					type
				} = answerTime
				// Put values for start and end time
				const queryValue = [answerTimeStart, answerTimeEnd]
				queryArray.push({
					value: queryValue,
					name: name,
					type: type
				})
			} else if (answererNumber && answererNumber.type === 'answererNumber') {
				// Put values for start and end time
				queryArray.push({
					value: answererNumber.value,
					name,
					type: answererNumber.type
				})
			} else if (
				typeof identifiers[0] === 'object' &&
				(identifiers[0].type === 'text' || identifiers[0].type === 'number')
			) {
				queryArray.push({
					value: identifiers[0].value,
					identifier: identifiers[0].identifier,
					type: identifiers[0].type,
					name: identifiers[0].value
				})
			} else {
				const startDate = getFirstDayOfYear(filterYearLeft)
				const endDate = getLastDayOfYear(filterYearRight)
				queryArray.push({
					name,
					value: 1,
					type: 'and',
					identifier: identifiers,
					startDate,
					endDate
				})
			}
		}
	)
	if (comparisonFilter.options.length > 0) {
		queryArray.unshift({ value: surveyId, type: 'survey', name: ownFilterName })
		if (comparisonFilter.options[0].type === 'age') {
			const currentYear = new Date().getFullYear()
			//Switch the age to year
			const queryValue = [
				currentYear - comparisonFilter.options[0].value[1],
				currentYear - comparisonFilter.options[0].value[0]
			]
			queryArray.unshift({
				identifier: comparisonFilter.options[0].identifier,
				name: ownFilterName,
				type: 'age',
				and: true,
				value: queryValue
			})
		} else if (
			typeof comparisonFilter.options[0] === 'object' &&
			(comparisonFilter.options[0].type === 'text' ||
				comparisonFilter.options[0].type === 'number')
		) {
			queryArray.push({
				value: comparisonFilter.options[0].value,
				identifier: comparisonFilter.options[0].identifier,
				type: comparisonFilter.options[0].type,
				name: ownFilterName
			})
		} else {
			queryArray.unshift({
				identifier: comparisonFilter.options,
				name: ownFilterName,
				type: 'and',
				value: 1
			})
		}
	} else if (comparisonFilter.type === 'answererNumber') {
		queryArray.unshift({ value: surveyId, type: 'survey', name: ownFilterName })
		if (comparisonFilter.type === 'answererNumber') {
			queryArray.unshift({
				value: comparisonFilter.value,
				name: ownFilterName,
				type: comparisonFilter.type
			})
		}
	} else if (comparisonFilter.type === 'answerTime') {
		queryArray.unshift({ value: surveyId, type: 'survey', name: ownFilterName })
		if (comparisonFilter.type === 'answerTime') {
			const { answerTimeStart, answerTimeEnd } = comparisonFilter.answerTime
			// Put values for start and end time
			const queryValue = [answerTimeStart, answerTimeEnd]
			queryArray.unshift({
				value: queryValue,
				name: ownFilterName,
				type: comparisonFilter.type
			})
		}
	}

	queryArray.forEach((value, index) => {
		queryObject.filters[index] = value
	})
	return qs.stringify(queryObject, { encode: false })
}

//Filter name limit
export const filterNameGenerator = name => {
	if (name.length > 40) {
		return `${name.substring(0, 40)}...`
	}
	return name
}

export const buildParams = (params, options = {}) => {
	return qs.stringify(params, options)
}
