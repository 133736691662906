import {
	AUTO_CREATE_SURVEY_SUCCESS,
	AUTO_SAVE_SURVEY_SUCCESS,
	CHANGE_IS_SURVEY_EDITED,
	CHECK_PUBLIC_LINK_VALID_SUCCESS,
	CLEAR_SURVEY_DRAFT,
	CREATE_SURVEY_SUCCESS,
	CLEAR_SURVEY_REMOTE,
	EDIT_SINGLE_SURVEY_LOCAL,
	GET_SINGLE_SURVEY_SUCCESS,
	GET_SURVEY_BY_PRIVATE_LINK_SUCCESS,
	GET_SURVEY_BY_PUBLIC_LINK_SUCCESS,
	GET_USER_SURVEYS,
	GET_USER_SURVEYS_FAIL,
	GET_USER_SURVEYS_SUCCESS,
	RESET_SURVEY_SUMMARY,
	UPDATE_ADD_MORE_MAILING_LIST,
	UPDATE_SURVEY_DRAFT,
	UPDATE_SURVEY_FILTER,
	UPDATE_SURVEY_REDUCER,
	UPDATE_SURVEY_REMINDER,
	UPDATE_SURVEY_SUCCESS,
	UPDATE_SURVEY_REMOTE,
	UPDATE_WHOLE_SURVEY_DRAFT,
	UPDATE_CONTENT,
	UPDATE_SURVEY,
	AUTO_SAVE_SURVEY,
	AUTO_CREATE_SURVEY,
	CREATE_SURVEY,
	CREATE_CONTENT,
	AUTO_SAVE_CONTENT,
	AUTO_CREATE_CONTENT,
	UPDATE_SURVEY_FAIL,
	AUTO_SAVE_SURVEY_FAIL,
	AUTO_CREATE_SURVEY_FAIL,
	CREATE_SURVEY_FAIL,
	UPDATE_CONTENT_SUCCESS,
	UPDATE_CONTENT_FAIL,
	CREATE_CONTENT_FAIL,
	CREATE_CONTENT_SUCCESS,
	AUTO_SAVE_CONTENT_SUCCESS,
	AUTO_SAVE_CONTENT_FAIL,
	AUTO_CREATE_CONTENT_SUCCESS,
	AUTO_CREATE_CONTENT_FAIL,
	CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS,
	SURVEY_GET_SURVEY_BY_TITLE,
	SURVEY_GET_SURVEY_BY_TITLE_SUCCESS,
	SURVEY_GET_SURVEY_BY_TITLE_FAIL,
	GET_ALL_FOLDERS,
	GET_ALL_FOLDERS_SUCCESS
} from '../actions/types'
import { sortByDate, sortByName } from '../helpers/sortHelper'
import {
	DEFAULT_DOMAIN,
	PUBLIC_LINK_STATUS,
	RESULT_STATUS,
	VIEW_MODE
} from '../constants'
import uuid from 'uuid/v4'
import { editItemInArray } from '../helpers/functionHelpers'

const initialState = {
	draft: {
		title: '',
		file: null,
		publicLink: uuid(),
		anonymous: true,
		//start date should be 1 hour from now by default
		openDate: new Date(Date.now() + 1000 * 3600).toISOString(),
		//end date should be 2 weeks from open date by default
		closeDate: new Date(Date.now() + 1000 * 3600 * 24 * 14).toISOString(),
		opennessPolicy: false,
		mailingList: [],
		senderEmail: '',
		sendByEmail: false,
		autoRefresh: false,
		maxAnswers: null,
		maxAnswersEnabled: false,
		receiverEmail: '',
		receiverEmailEnabled: false,
		emailSubject: '',
		emailContent: '',
		publicLinkEnabled: false,
		endingTextHeader: '',
		surveyThankYou: '',
		dateEnabled: false,
		createdFrom: -1,
		id: -1,
		createdFromTemplateName: '',
		status: RESULT_STATUS.DRAFT,
		reminders: []
	},
	answer: {
		id: '',
		title: '',
		publicLink: '',
		anonymous: true,
		openDate: '',
		closeDate: '',
		opennessPolicy: false,
		mailingList: [],
		senderEmail: '',
		endingTextHeader: '',
		surveyThankYou: '',
		availableSurveyLanguages: [],
		defaultLanguage: 'FI',
		autoRefresh: false
	},
	remote: {
		mailingList: [],
		publicLinkEnabled: false,
		publicLink: '',
		sendByEmail: false
	},
	reminder: {
		emailContent: '',
		emailSubject: ''
	},
	addMoreMailingList: [],
	userSurveys: [],
	totalSurveys: 0,
	loading: false,
	filter1: 1,
	filter2: 0,
	filter3: 0,
	publicLinkValid: PUBLIC_LINK_STATUS.DEFAULT,
	isSurveyEdited: false,
	publicLinkDomain: DEFAULT_DOMAIN,
	viewMode: VIEW_MODE.EDIT_ALL,
	numberOfAnswers: null,
	isManualSaving: false,
	isAutoSaving: false,
	folders: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SURVEY_DRAFT:
			return {
				...state,
				draft: { ...state.draft, [action.payload.key]: action.payload.value }
			}
		case GET_SINGLE_SURVEY_SUCCESS:
			const survey = action.payload.data
			return {
				...state,
				answer: {
					...state.answer,
					id: survey.id,
					title: survey.title,
					file: survey.file,
					publicLink: survey.publicLink,
					anonymous: survey.anonymous,
					openDate: survey.openDate,
					closeDate: survey.closeDate,
					opennessPolicy: survey.opennessPolicy,
					mailingList: survey.mailingLists,
					senderEmail: survey.senderEmail ? survey.senderEmail : '',
					autoRefresh: survey.autoRefresh ? survey.autoRefresh : false,
					maxAnswers: survey.maxAnswers ? survey.maxAnswers : null
				}
			}
		case GET_SURVEY_BY_PUBLIC_LINK_SUCCESS: {
			const survey = action.payload.data
			return {
				...state,
				answer: {
					...state.answer,
					id: survey.id,
					title: survey.title,
					file: survey.file,
					publicLink: survey.publicLink,
					anonymous: survey.anonymous,
					openDate: survey.openDate,
					closeDate: survey.closeDate,
					opennessPolicy: survey.opennessPolicy,
					mailingList: survey.mailingLists,
					senderEmail: survey.senderEmail || '',
					surveyThankYou: survey.endingText || '',
					endingTextHeader: survey.endingTextHeader || '',
					availableSurveyLanguages:
						(survey.content && survey.content.availableSurveyLanguages) || [],
					defaultLanguage:
						(survey.content && survey.content.defaultLanguage) || 'FI',
					autoRefresh: survey.autoRefresh || false
				}
			}
		}
		case GET_SURVEY_BY_PRIVATE_LINK_SUCCESS:
			const surveyClone = action.payload.data
			return {
				...state,
				answer: {
					...state.answer,
					id: surveyClone.id,
					title: surveyClone.title,
					file: surveyClone.file,
					publicLink: surveyClone.publicLink,
					anonymous: surveyClone.anonymous,
					openDate: surveyClone.openDate,
					closeDate: surveyClone.closeDate,
					opennessPolicy: surveyClone.opennessPolicy,
					mailingList: surveyClone.mailingLists,
					senderEmail: surveyClone.senderEmail || '',
					surveyThankYou: surveyClone.endingText || '',
					endingTextHeader: surveyClone.endingTextHeader || '',
					availableSurveyLanguages:
						(surveyClone.content &&
							surveyClone.content.availableSurveyLanguages) ||
						[],
					defaultLanguage:
						(surveyClone.content && surveyClone.content.defaultLanguage) ||
						'FI',
					autoRefresh: surveyClone.autoRefresh || false
				}
			}

		case CLEAR_SURVEY_DRAFT:
			return {
				...state,
				draft: { ...initialState.draft, publicLink: uuid() }
			}
		case GET_USER_SURVEYS:
			return { ...state, loading: true }
		case GET_USER_SURVEYS_FAIL:
			return { ...state, loading: false }
		case GET_USER_SURVEYS_SUCCESS:
			const { surveys, total } = action.payload.data
			return {
				...state,
				loading: false,
				userSurveys:
					state.totalSurveys !== total
						? [...surveys]
						: [...new Set([...state.userSurveys, ...surveys])],
				totalSurveys: total
			}
		case RESET_SURVEY_SUMMARY:
		case CREATE_TEMP_ORGANISATION_ADMIN_SUCCESS:
			return {
				...state,
				userSurveys: initialState.userSurveys,
				totalSurveys: initialState.totalSurveys
			}

		case UPDATE_WHOLE_SURVEY_DRAFT:
			return { ...state, draft: { ...action.payload.survey } }
		case UPDATE_SURVEY_FILTER:
			return {
				...state,
				[action.payload.filter]: action.payload.value,
				userSurveys:
					state[action.payload.filter] === action.payload.value
						? state.userSurveys
						: []
			}
		case CHECK_PUBLIC_LINK_VALID_SUCCESS:
			return {
				...state,
				publicLinkValid: action.payload.data.isExist
					? PUBLIC_LINK_STATUS.INVALID
					: PUBLIC_LINK_STATUS.VALID
			}
		case CHANGE_IS_SURVEY_EDITED:
			return {
				...state,
				isSurveyEdited: action.payload.value
			}
		case UPDATE_SURVEY_REMINDER:
			return {
				...state,
				reminder: {
					...state.reminder,
					[action.payload.key]: action.payload.value
				}
			}
		case UPDATE_ADD_MORE_MAILING_LIST:
			return {
				...state,
				addMoreMailingList: action.payload.mailingList
			}
		case UPDATE_SURVEY_REDUCER:
			return {
				...state,
				[action.payload.key]: action.payload.value
			}
		case UPDATE_SURVEY_SUCCESS:
		case UPDATE_SURVEY_FAIL:
		case CREATE_SURVEY_SUCCESS:
		case CREATE_SURVEY_FAIL:
		case UPDATE_CONTENT_SUCCESS:
		case UPDATE_CONTENT_FAIL:
		case CREATE_CONTENT_FAIL:
		case CREATE_CONTENT_SUCCESS:
			return {
				...state,
				isSurveyEdited: false,
				isManualSaving: false
			}
		case AUTO_SAVE_SURVEY_SUCCESS:
		case AUTO_SAVE_SURVEY_FAIL:
		case AUTO_CREATE_SURVEY_SUCCESS:
		case AUTO_CREATE_SURVEY_FAIL:
		case AUTO_SAVE_CONTENT_SUCCESS:
		case AUTO_SAVE_CONTENT_FAIL:
		case AUTO_CREATE_CONTENT_SUCCESS:
		case AUTO_CREATE_CONTENT_FAIL:
			return { ...state, isSurveyEdited: false, isAutoSaving: false }
		case EDIT_SINGLE_SURVEY_LOCAL:
			return {
				...state,
				userSurveys: editItemInArray(
					state.userSurveys,
					action.payload.newSurvey,
					action.payload.surveyIndex
				)
			}
		case UPDATE_SURVEY_REMOTE:
			return {
				...state,
				remote: { ...state.remote, ...action.payload.survey }
			}
		case CLEAR_SURVEY_REMOTE:
			return {
				...state,
				remote: { ...initialState.remote }
			}
		case UPDATE_SURVEY:
		case CREATE_SURVEY:
		case UPDATE_CONTENT:
		case CREATE_CONTENT:
			return { ...state, isManualSaving: true }
		case AUTO_SAVE_SURVEY:
		case AUTO_CREATE_SURVEY:
		case AUTO_SAVE_CONTENT:
		case AUTO_CREATE_CONTENT:
			return { ...state, isAutoSaving: true }
		// Searching survey by title
		case SURVEY_GET_SURVEY_BY_TITLE:
			return {
				...state,
				loading: true
			}
		case SURVEY_GET_SURVEY_BY_TITLE_SUCCESS: {
			const { surveys, total } = action.payload.data
			return {
				...state,
				userSurveys: surveys,
				totalSurveys: total,
				loading: false
			}
		}
		case SURVEY_GET_SURVEY_BY_TITLE_FAIL:
			return {
				...state,
				loading: false
			}
		case GET_ALL_FOLDERS_SUCCESS:
			return {
				...state,
				folders: action.payload.data
			}
		default:
			return state
	}
}

export default reducer

export const getDraftSurveyTitle = (state = initialState) => state.draft.title

export const getDraftPublicLink = (state = initialState) =>
	state.draft.publicLink

export const getDraftAnonymous = (state = initialState) => state.draft.anonymous

export const getDraftOpenDate = (state = initialState) => state.draft.openDate

export const getDraftCloseDate = (state = initialState) => state.draft.closeDate

export const getOpennessPolicy = (state = initialState) =>
	state.draft.opennessPolicy

export const getDraftMailingList = (state = initialState) =>
	state.draft.mailingList
export const getDraftFile = (state = initialState) => state.draft.file
export const getAnswerFile = (state = initialState) => state.answer.file
export const getDraftSenderEmail = (state = initialState) =>
	state.draft.senderEmail
export const getDraftSendByEmail = (state = initialState) =>
	state.draft.sendByEmail

export const getDraftEmailSubject = (state = initialState) =>
	state.draft.emailSubject
export const getDraftEmailContent = (state = initialState) =>
	state.draft.emailContent
export const getDraftPublicLinkEnabled = (state = initialState) =>
	state.draft.publicLinkEnabled
export const getDraftAutoRefresh = (state = initialState) =>
	state.draft.autoRefresh
export const getDraftMaxAnswers = (state = initialState) =>
	state.draft.maxAnswers
export const getDraftMaxAnswersEnabled = (state = initialState) =>
	state.draft.maxAnswersEnabled
export const getDraftReceiverEmail = (state = initialState) =>
	state.draft.receiverEmail
export const getDraftReceiverEmailEnabled = (state = initialState) =>
	state.draft.receiverEmailEnabled
export const getDraftSurveyThankYou = (state = initialState) =>
	state.draft.surveyThankYou || ''
export const getDraftSurvey = (state = initialState) => ({
	anonymous: state.draft.anonymous,
	openDate: state.draft.openDate,
	closeDate: state.draft.closeDate,
	endingText: state.draft.surveyThankYou,
	endingTextHeader: state.draft.endingTextHeader,
	opennessPolicy: state.draft.opennessPolicy,
	publicLink: state.draft.publicLink,
	title: state.draft.title,
	autoRefresh: state.draft.autoRefresh,
	maxAnswers: state.draft.maxAnswers,
	maxAnswersEnabled: state.draft.maxAnswersEnabled
})

export const getDraftDateEnabled = (state = initialState) =>
	state.draft.dateEnabled

export const getAnswerSurvey = (state = initialState) => ({
	anonymous: state.answer.anonymous,
	openDate: state.answer.openDate,
	closeDate: state.answer.closeDate,
	mailingLists: state.answer.mailingList,
	opennessPolicy: state.answer.opennessPolicy,
	autoRefresh: state.answer.autoRefresh,
	publicLink: state.answer.publicLink,
	title: state.answer.title,
	id: state.answer.id,
	availableSurveyLanguages: state.answer.availableSurveyLanguages,
	defaultLanguage: state.answer.defaultLanguage
})

export const getAnswerSurveyTitle = (state = initialState) =>
	state.answer ? state.answer.title : ''

export const surveySelector = {
	getFolders: (state = initialState) => state.folders,
	getFilter1: (state = initialState) => state.filter1,
	getFilter2: (state = initialState) => state.filter2,
	getFilter3: (state = initialState) => state.filter3,
	getUserSurveys: (state = initialState) => {
		return state.userSurveys.sort((survey1, survey2) =>
			_sortSurvey(survey1, survey2, state.filter3)
		)
	},
	getTotalSurveys: (state = initialState) => state.totalSurveys,
	publicLinkValid: (state = initialState) => state.publicLinkValid,
	getCreatedFrom: (state = initialState) => state.draft.createdFrom,
	getDraftSurveyId: (state = initialState) => state.draft.id,
	getCreatedFromTemplateName: (state = initialState) =>
		state.draft.createdFromTemplateName,
	getSurveyStatus: (state = initialState) => state.draft.status,
	getEndingTextHeader: (state = initialState) =>
		state.draft.endingTextHeader || '',
	getIsSurveyEdited: (state = initialState) => state.isSurveyEdited,
	getDraftSurveyComparable: (state = initialState) => ({
		anonymous: state.draft.anonymous,
		openDate: state.draft.openDate,
		closeDate: state.draft.closeDate,
		endingText: state.draft.surveyThankYou,
		endingTextHeader: state.draft.endingTextHeader,
		opennessPolicy: state.draft.opennessPolicy,
		publicLink: state.draft.publicLink,
		title: state.draft.title,
		emailSubject: state.draft.emailSubject,
		emailContent: state.draft.emailContent,
		senderEmail: state.draft.senderEmail,
		mailingList: state.draft.mailingList,
		autoRefresh: state.draft.autoRefresh
	}),
	getAnswerSurveyThankYou: (state = initialState) =>
		state.answer.surveyThankYou || '',
	getAnswerEndingTextHeader: (state = initialState) =>
		state.answer.endingTextHeader || '',
	getReminderEmailContent: (state = initialState) =>
		state.reminder.emailContent,
	getReminderEmailSubject: (state = initialState) =>
		state.reminder.emailSubject,
	getAddMoreMailingList: (state = initialState) => state.addMoreMailingList,
	getReminders: (state = initialState) => state.draft.reminders,
	getPublicLinkDomain: (state = initialState) => state.publicLinkDomain,
	getViewMode: (state = initialState) => state.viewMode,
	getOriginalSurveys: (state = initialState) => state.userSurveys,
	getSurveyRemote: (state = initialState) => state.remote,
	getNumberOfAnswers: (state = initialState) => state.numberOfAnswers,
	getIsSaving: (state = initialState) =>
		state.isManualSaving || state.isAutoSaving,
	getIsAutoSaving: (state = initialState) => state.isAutoSaving
}

const _sortSurvey = (survey1, survey2, sort) => {
	if (sort === 0) {
		return sortByDate(survey1, survey2, 'createdAt')
	} else if (sort === 1) {
		return sortByDate(survey1, survey2, 'updatedAt')
	} else if (sort === 2) {
		return sortByName(survey1.title, survey2.title)
	}
}
