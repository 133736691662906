import { GET_CONTENT_TRANSLATIONS_SUCCESS } from '../actions/types'

const initialState = {
	contentTranslations: [],
	sectionTranslations: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CONTENT_TRANSLATIONS_SUCCESS:
			return { ...state, contentTranslations: action.payload.data }
		// case GET_SECTION_TRANSLATIONS_SUCCESS:
		// 	return { ...state, sectionTranslations: action.payload.data }
		default:
			return state
	}
}

export default reducer

export const contentTranslationsSelector = (state = initialState) =>
	state.contentTranslations
export const sectionTranslationsSelector = (state = initialState) =>
	state.sectionTranslations
