import {
	ADD_PREVIEW_SECTIONS,
	GET_SINGLE_CONTENT_SUCCESS,
	UPDATE_PREVIEW_QUESTION_DRAFT
} from '../actions/types'
import produce from 'immer'
import { reorderSection } from '../helpers/reducerHelper'

const initialState = []

const reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case ADD_PREVIEW_SECTIONS:
				return [...action.payload.sections]
			case UPDATE_PREVIEW_QUESTION_DRAFT:
				// Update question draft
				draft[action.payload.sectionIndex].questions[
					action.payload.questionIndex
				][action.payload.key] = action.payload.value
				return
			case GET_SINGLE_CONTENT_SUCCESS:
				return reorderSection(action.payload.data.sections)
		}
	})

export default reducer

export const previewSectionSelector = {
	getAllSections: (state = initialState) => state
}
