import { apiWrapper, buildParams } from '../helpers/apiHelpers'
import {
	CLEAR_PRINT_DATA,
	GENERATE_EXCEL,
	GENERATE_PDF,
	GENERATE_SCREENSHOT,
	UPDATE_DOWNLOAD_STATUS,
	UPDATE_PRINT_DATA,
	UPDATE_PRINT_STATUS,
	UPDATE_SURVEY
} from './types'

export const updatePrintData = data => ({
	type: UPDATE_PRINT_DATA,
	payload: {
		data
	}
})

export const clearPrintData = () => ({
	type: CLEAR_PRINT_DATA
})

export const updatePrintStatus = status => ({
	type: UPDATE_PRINT_STATUS,
	payload: {
		status
	}
})

export const generatePdf = data => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GENERATE_PDF, {
			method: 'POST',
			url: `/files/pdf`,
			data,
			responseType: 'blob'
		})
	)
}

export const generateScreenshot = data => (dispatch, getState) => {
	return dispatch(
		apiWrapper(GENERATE_SCREENSHOT, {
			method: 'POST',
			url: `/files/screenShot`,
			data,
			responseType: 'blob'
		})
	)
}

export const updateDownloadStatus = value => ({
	type: UPDATE_DOWNLOAD_STATUS,
	payload: {
		value
	}
})

export const generateExcel = (data, surveyId, surveys) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(GENERATE_EXCEL, {
			method: 'POST',
			url: `/files/xlsx/${surveyId}${
				Array.isArray(surveys) && surveys.length
					? `?${surveys.map(surveyId => `survey=${surveyId}`).join('&')}`
					: ''
			}`,
			data,
			responseType: 'blob'
		})
	)
}
export const generateTemplateExcel = (data, templateId) => (
	dispatch,
	getState
) => {
	return dispatch(
		apiWrapper(GENERATE_EXCEL, {
			method: 'POST',
			url: `/files/xlsx/templates/${templateId}`,
			data,
			responseType: 'blob'
		})
	)
}
export const updateSurveyImage = (surveyId, file) => (dispatch, getState) => {
	return dispatch(
		apiWrapper(UPDATE_SURVEY, {
			method: 'PATCH',
			url: `/files/surveys/${surveyId}`,
			data: file
		})
	)
}
