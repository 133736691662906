import moment from 'moment'
//Date format
export const toLocaleString = (date, options) => {
	//options should look like this {
	// 			month: '2-digit',
	// 			day: '2-digit',
	// 			year: 'numeric'
	// 		}
	return new Date(date).toLocaleString('fi-FI', options)
}

export const formatDate = (date, format = 'YYYY-MM-DD') => {
	return moment(date).format(format)
}

//Check the time if it's in the future or in the past
export const fromNow = date => {
	return new Date(date) - Date.now()
}

//Check if time is within 30minutes, used in formDashboard. It returns true if time is more than half an hour from the current time
export const halfHourFromNow = date => {
	const addHalfHour = () =>
		moment()
			.add(30, 'minutes')
			.toDate()
			.getTime()
	return date > addHalfHour()
}

//Compare 2 date
export const compareDateAsc = (date1, date2) => {
	return new Date(date1) - new Date(date2)
}

//Compare 2 date descending
export const compareDateDes = (date1, date2) => {
	return new Date(date2) - new Date(date1)
}
/**
 * Get first day of given year
 * @param year number
 * @param format string
 * @return {string}
 */
export const getFirstDayOfYear = (year, format = 'YYYY-MM-DD') => {
	return moment()
		.year(year)
		.startOf('year')
		.format(format)
}

/**
 * Get last day of given year
 * @param year number
 * @param format string
 * @return {string}
 */
export const getLastDayOfYear = (year, format = 'YYYY-MM-DD') => {
	return moment()
		.year(year)
		.endOf('year')
		.format(format)
}
